import React, { Component } from 'react';
import Config from '../../helpers/config/Config';
import ContentEditable from '../../components/common/ContentEditable';
import { Tooltip, Position, Menu, ContextMenu, MenuItem } from '@blueprintjs/core';
import { Route } from 'react-router';
import { IconNames } from '@blueprintjs/icons';
import _ from "lodash";

export class VerticalLine extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <div className="timeLine-main-data-verticalLine" style={{ left: this.props.left }} />;
  }
}

export class TaskRow extends Component {
  constructor(props) {
    super(props);
  }

  onChange = (value) => {
    if (this.props.onUpdateTask) {
      this.props.onUpdateTask(this.props.item, { name: value });
    }
  };

  render() {
    return (
      <Route
        render={({history}) => (
          <div
            className="timeLine-side-task-row"
            style={{
              ...Config.values.taskList.task.style,
              top: this.props.top,
              height: this.props.itemheight
            }}
            onClick={(e) => this.props.onSelectItem(this.props.item)}
            onContextMenu={(e) => {
              if (this.props.allowContextMenu !== false) {
                e.stopPropagation();
                e.preventDefault();
                this.showContextMenu(e, history);
              }
            }}
          >
            {this.props.nonEditable ? (
                <div tabIndex={this.props.index} style={{ width: '100%' }}>
                  {/* <Tooltip
                    position={Position.LEFT}
                    content={`${this.props.item.label} - ${this.props.item.name}`}
                    style={{
                      
                    }}
                  > */}
                    {this.props.label}
                  {/* </Tooltip> */}
                </div>
            ) : (
              <ContentEditable value={this.props.label} index={this.props.index} onChange={this.onChange} />
            )}
          </div>
        )}
      />
    );
  }

  showContextMenu = (
    e,
    history,
    clientX,
    clientY,
  ) => {
    // e.stopPropagation();
    // // must prevent default to cancel parent's context menu
    // e.preventDefault();
    // invoke static API, getting coordinates from mouse event
    ContextMenu.show(
        <Menu>
            {/* <MenuItem icon="search-around" text="Search around..." />
            <MenuItem icon="search" text="Object viewer" /> */}
            {/* <MenuItem icon="remove" text="Remove" /> */}
            {/* <MenuItem
                icon={IconNames.APPLICATION}
                text="Open"
                onClick={() => {
                    this.openTaskPage(history);
                }}
            /> */}
            {/* <MenuItem
                icon={IconNames.APPLICATION}
                text="Open in dialog"
                onClick={this.openEditTaskDialog}
            /> */}
            <MenuItem icon={IconNames.APPLICATIONS} text="Open in new tab" onClick={this.openInNewTab}/>
            <MenuItem icon={IconNames.REFRESH} text="Reset" onClick={this.resetItem}/>
            <MenuItem icon={IconNames.DELETE} text="Remove from timeline" onClick={this.deleteItem}/>
            {/* <MenuItem icon={IconNames.MOBILE_VIDEO} text="Call person in charge" />
            <MenuDivider />
            <MenuItem icon={IconNames.LIST_COLUMNS} text="Column">
                <MenuItem icon="bold" text="Bold" />
                <MenuItem icon="italic" text="Italic" />
                <MenuItem icon="underline" text="Underline" />
            </MenuItem>
            <MenuItem icon={IconNames.LIST} text="Priority">
                <MenuItem icon="bold" text="Bold" />
                <MenuItem icon="italic" text="Italic" />
                <MenuItem icon="underline" text="Underline" />
            </MenuItem>
            <MenuDivider />
            <MenuItem icon={IconNames.REMOVE}  text="Remove" /> */}
            {/* <MenuItem disabled={true} text="Clicked on node" /> */}
        </Menu>,
        { left: clientX ? clientX : e.clientX, top: clientY ? clientY : e.clientY },
        () => {return; }, // this.setState({ isContextMenuOpen: false }),
    );
    // indicate that context menu is open so we can add a CSS class to this element
    // this.setState({ isContextMenuOpen: true });
  }

  openInNewTab = () => {
    if (!_.isEmpty(this.props.item.projectShortcode) && !_.isUndefined(this.props.item.projectShortcode)) {
      window.open(`/projects/${this.props.item.projectShortcode}/${this.props.item.taskID}`);
    }
  }

  openEditTaskDialog = () => {
    // Do nothing
  }
  
  resetItem = () => {
    if (!_.isUndefined(this.props.onResetItem)) {
      this.props.onResetItem(this.props.item);
    }
  }

  deleteItem = () => {
    if (!_.isUndefined(this.props.onDeleteItem)) {
      this.props.onDeleteItem(this.props.item);
    }
  }
}

export default class TaskList extends Component {
  constructor(props) {
    super(props);
  }

  getContainerStyle(rows) {
    let new_height = rows > 0 ? rows * this.props.itemheight : 10;
    return { height: new_height };
  }

  renderTaskRow(data) {
    let result = [];
    for (let i = this.props.startRow; i < this.props.endRow + 1; i++) {
      let item = data[i];
      if (!item) break;
      result.push(
        <TaskRow
          key={i}
          index={i}
          item={item}
          label={item.name}
          top={i * this.props.itemheight}
          itemheight={this.props.itemheight}
          isSelected={this.props.selectedItem == item}
          onUpdateTask={this.props.onUpdateTask}
          onSelectItem={this.props.onSelectItem}
          onResetItem={this.props.onResetItem}
          onDeleteItem={this.props.onDeleteItem}
          nonEditable={this.props.nonEditable}
        />
      );
    }
    return result;
  }

  doScroll = () => {
    this.props.onScroll(this.refs.taskViewPort.scrollTop);
  };

  render() {
    let data = this.props.data ? this.props.data : [];
    this.containerStyle = this.getContainerStyle(data.length);
    return (
      <div className="timeLine-side">
        <div className="timeLine-side-title" style={Config.values.taskList.title.style}>
          <div>{Config.values.taskList.title.label}</div>
        </div>
        <div ref="taskViewPort" className="timeLine-side-task-viewPort" onScroll={this.doScroll}>
          <div className="timeLine-side-task-container" style={this.containerStyle}>
            {this.renderTaskRow(data)}
          </div>
        </div>
      </div>
    );
  }
}
