exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".buttonStyles_buttonWrapper__kTx6K {\n  display: inline-block;\n}\n\n.buttonStyles_button__2GEA9 {\n  background: #fbfbfb;\n  color: #888;\n  font-size: 18px;\n  border: 0;\n  padding-top: 5px;\n  vertical-align: bottom;\n  height: 34px;\n  width: 36px;\n}\n\n.buttonStyles_button__2GEA9 svg {\n  fill: #888;\n}\n\n.buttonStyles_button__2GEA9:hover, .buttonStyles_button__2GEA9:focus {\n  background: #f3f3f3;\n  outline: 0; /* reset for :focus */\n}\n\n.buttonStyles_active__2kIco {\n  background: #efefef;\n  color: #444;\n}\n\n.buttonStyles_active__2kIco svg {\n  fill: #444;\n}\n", ""]);

// exports
exports.locals = {
	"buttonWrapper": "buttonStyles_buttonWrapper__kTx6K",
	"button": "buttonStyles_button__2GEA9",
	"active": "buttonStyles_active__2kIco"
};