/*!
 * Copyright 2019 CTC. All rights reserved.
 *
 * Licensed under the terms of the LICENSE file distributed with this label.
 */

import _ from "lodash";
import { delay } from "redux-saga";
import { all, call, fork, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { IApplicationState } from "..";
import { callApiWithAuthToken } from "../../utils/api";
import {
  CONST_COOKIE_AUTHENTICATION_TOKEN,
} from "../../utils/constants";
import { IStringTMap } from "../../utils/types";
import { insertOrUpdateLabelBoard } from "../board/actions";
import {
  addOrRemoveLabelManagerRequest,
  createLabelRequest,
  createLabelSetError,
  createLabelSetResult,
  deleteLabelRequest,
  getLabelRequest,
  updateLabelColorRequest,
  updateLabelSortOrderRequest,
  updateLabelTitleRequest,
} from "./actions";
import {
  ILabel,
  ILabelCommonResult,
  ILabelGetResult,
  LabelsActionTypes,
} from "./types";

const API_ENDPOINT: string = process.env.REACT_APP_TASK_RIPPLE_API!;

const getAuthToken = (state: IApplicationState) =>
  // state.cookies.hasCookies ? state.cookies.cookies!.get(CONST_COOKIE_AUTHENTICATION_TOKEN) : "";
  state.logins.result.authtoken ? state.logins.result.authtoken! : "";

const getCookies = (state: IApplicationState) =>
  state.cookies.cookies;

// function* handleGetLabels(action: ReturnType<typeof getLabelsRequest>) {
//   try {
//     // To call async functions, use redux-saga's `call()`.
//     const authToken = yield select(getAuthToken);
//     const res = yield call(callApiWithAuthToken, "get", API_ENDPOINT, "/label", authToken);

//     if (res.error) {
//       yield put(getLabelsSetError(res.error));
//     } else {
//       yield put(getLabelsSetResult(res));
//     }
//   } catch (err) {
//     if (err instanceof Error) {
//       yield put(getLabelsSetError(err.stack!));
//     } else {
//       yield put(getLabelsSetError("An unknown error occured."));
//     }
//   }
// }

// // This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// // type, and run our saga
// function* watchGetLabelsRequest() {
//   yield takeLatest(LabelsActionTypes.GET_LABELS_REQUEST, handleGetLabels);
// }

function* handleGetLabel(action: ReturnType<typeof getLabelRequest>) {
  try {
    // To call async functions, use redux-saga's `call()`.
    const authToken = yield select(getAuthToken);
    const res = yield call(callApiWithAuthToken, "get", API_ENDPOINT, "/label/" + action.payload, authToken);

    if (res.error) {
      // yield put(getLabelSetError(res.error));
    } else {
      // yield put(getLabelSetResult(res));
      // Insert to board
      const resultData: ILabelGetResult = res;
      if (!_.isUndefined(resultData.label)) {
        yield put(insertOrUpdateLabelBoard(resultData.label!));
      }
    }
  } catch (err) {
    if (err instanceof Error) {
      // yield put(getLabelSetError(err.stack!));
    } else {
      // yield put(getLabelSetError("An unknown error occured."));
    }
  }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga
function* watchGetLabelRequest() {
  yield takeLatest(LabelsActionTypes.GET_LABEL_REQUEST, handleGetLabel);
}

function* handleCreateLabelRequest(action: ReturnType<typeof createLabelRequest>) {
  try {
    // To call async functions, use redux-saga's `call()`.
    const authToken = yield select(getAuthToken);
    const res = yield call(callApiWithAuthToken, "put", API_ENDPOINT, "/label", authToken, action.payload);
    const cookies = yield select(getCookies);

    const resultData: ILabelCommonResult = res;
    yield put(createLabelSetResult(resultData));
    // Load the label
    if (!_.isUndefined(resultData.id)) {
      yield put(getLabelRequest(resultData.id));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(createLabelSetError(err.stack!));
    } else {
      yield put(createLabelSetError("An unknown error occured."));
    }
  }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga
function* watchCreateLabelRequest() {
  yield takeEvery(LabelsActionTypes.CREATE_LABEL_REQUEST, handleCreateLabelRequest);
}

function* handleUpdateLabelTitleRequest(action: ReturnType<typeof updateLabelTitleRequest>) {
  try {
    yield call(delay, 1000);  // Debouncing written by takeLatest
                              // Credit: https://gist.github.com/Calvin-Huang/698cbb954d714a41c1726d0cee1be629
    // To call async functions, use redux-saga's `call()`.
    const authToken = yield select(getAuthToken);
    const res = yield call(callApiWithAuthToken,
      "post",
      API_ENDPOINT,
      "/label/updatetitle",
      authToken,
      action.payload);

    const resultData: ILabelCommonResult = res;
    if (!_.isUndefined(resultData.id)) {
      // Load the label from server
      yield put(getLabelRequest(resultData.id));
    }

  } catch (err) {
    if (err instanceof Error) {
      // yield put(createLabelSetError(err.stack!));
    } else {
      // yield put(createLabelSetError("An unknown error occured."));
    }
  }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga
function* watchUpdateLabelTitleRequest() {
  yield takeLatest(LabelsActionTypes.UPDATE_LABEL_TITLE_REQUEST, handleUpdateLabelTitleRequest);
}

function* handleAddOrRemoveLabelManagerRequest(action: ReturnType<typeof addOrRemoveLabelManagerRequest>) {
  try {
    // yield call(delay, 1000);  // Debouncing written by takeLatest
    //                           // Credit: https://gist.github.com/Calvin-Huang/698cbb954d714a41c1726d0cee1be629
    // To call async functions, use redux-saga's `call()`.
    const authToken = yield select(getAuthToken);
    const res = yield call(callApiWithAuthToken,
      "post",
      API_ENDPOINT,
      "/label/addorremovemanager",
      authToken,
      action.payload);

    const resultData: ILabelCommonResult = res;
    // yield put(createProjectSetResult(resultData));
    // // Reload the projects
    // yield put(getProjectsRequest());

  } catch (err) {
    if (err instanceof Error) {
      // yield put(createProjectSetError(err.stack!));
    } else {
      // yield put(createProjectSetError("An unknown error occured."));
    }
  }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga
function* watchAddOrRemoveLabelManagerRequest() {
  yield takeEvery(
    LabelsActionTypes.ADD_OR_REMOVE_LABEL_MANAGER_REQUEST,
    handleAddOrRemoveLabelManagerRequest,
  );
}

function* handleUpdateLabelSortOrderRequest(action: ReturnType<typeof updateLabelSortOrderRequest>) {
  try {
    // yield call(delay, 1000);  // Debouncing written by takeLatest
                              // Credit: https://gist.github.com/Calvin-Huang/698cbb954d714a41c1726d0cee1be629
    // To call async functions, use redux-saga's `call()`.
    const authToken = yield select(getAuthToken);
    const res = yield call(callApiWithAuthToken,
      "post",
      API_ENDPOINT,
      "/label/updatesortorder",
      authToken,
      action.payload);

    const resultData: ILabelCommonResult = res;
    // yield put(createProjectSetResult(resultData));
    // // Reload the projects
    // yield put(getProjectsRequest());

  } catch (err) {
    if (err instanceof Error) {
      // yield put(createProjectSetError(err.stack!));
    } else {
      // yield put(createProjectSetError("An unknown error occured."));
    }
  }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga
function* watchUpdateLabelSortOrderRequest() {
  yield takeEvery(LabelsActionTypes.UPDATE_LABEL_SORT_ORDER_REQUEST, handleUpdateLabelSortOrderRequest);
}

function* handleDeleteLabelRequest(action: ReturnType<typeof deleteLabelRequest>) {
  try {
    // To call async functions, use redux-saga's `call()`.
    const authToken = yield select(getAuthToken);
    const res = yield call(callApiWithAuthToken,
      "delete",
      API_ENDPOINT,
      "/label",
      authToken,
      action.payload);

    const resultData: ILabelCommonResult = res;
    // yield put(createProjectSetResult(resultData));
    // // Reload the projects
    // yield put(getProjectsRequest());

  } catch (err) {
    if (err instanceof Error) {
      // yield put(createProjectSetError(err.stack!));
    } else {
      // yield put(createProjectSetError("An unknown error occured."));
    }
  }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga
function* watchDeleteLabelRequest() {
  yield takeEvery(LabelsActionTypes.DELETE_LABEL_REQUEST, handleDeleteLabelRequest);
}

function* handleUpdateLabelColorRequest(action: ReturnType<typeof updateLabelColorRequest>) {
  try {
    yield call(delay, 1000);  // Debouncing written by takeLatest
                              // Credit: https://gist.github.com/Calvin-Huang/698cbb954d714a41c1726d0cee1be629
    // To call async functions, use redux-saga's `call()`.
    const authToken = yield select(getAuthToken);
    const res = yield call(callApiWithAuthToken,
      "post",
      API_ENDPOINT,
      "/label/updatecolor",
      authToken,
      action.payload);

    const resultData: ILabelCommonResult = res;
    if (!_.isUndefined(resultData.id)) {
      // Load the label from server
      yield put(getLabelRequest(resultData.id));
    }

  } catch (err) {
    if (err instanceof Error) {
      // yield put(createLabelSetError(err.stack!));
    } else {
      // yield put(createLabelSetError("An unknown error occured."));
    }
  }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga
function* watchUpdateLabelColorRequest() {
  yield takeLatest(LabelsActionTypes.UPDATE_LABEL_COLOR_REQUEST, handleUpdateLabelColorRequest);
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* labelsSaga() {
  yield all([
    fork(watchCreateLabelRequest),
    fork(watchGetLabelRequest),
    fork(watchUpdateLabelTitleRequest),
    fork(watchAddOrRemoveLabelManagerRequest),
    fork(watchUpdateLabelSortOrderRequest),
    fork(watchDeleteLabelRequest),
    fork(watchUpdateLabelColorRequest),
  ]);
}

export default labelsSaga;
