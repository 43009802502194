/*!
 * Copyright 2019 CTC. All rights reserved.
 *
 * Licensed under the terms of the LICENSE file distributed with this project.
 */

import { Button, Intent, Spinner } from "@blueprintjs/core";
import { timeline } from "console";
import _, { delay } from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Dispatch } from "redux";
import styled from "styled-components";
import Board from "../../components/board/board";
// import TimeLine from "react-gantt-timeline";
import TimeLine from "../../libs/react-timeline-gantt/TimeLine";
import { IApplicationState, IConnectedReduxProps } from "../../store";
import * as boardActions from "../../store/board/actions";
import { IColumn } from "../../store/columns/types";
import { IActiveUserProfile } from "../../store/logins/types";
import { IPriority } from "../../store/priorities/types";
import { IProject } from "../../store/projects/types";
import { ITask } from "../../store/tasks/types";
import * as timelineActions from "../../store/timelines/actions";
import { ITimeline, ITimelineTask, IUpdateTimelineTaskInput, TimelineGroupBy } from "../../store/timelines/types";
import { getDateFromUTCEpoch, getEpochSecondsOfDate } from "../../utils/dates";
import { IStringTMap } from "../../utils/types";

const LoadingContainer = styled.div`
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding: 15px;
`;

interface ITimelineItem {
  id: string;
  taskID: string;
  projectShortcode: string;
  timelineID: string;
  timelineTask: ITimelineTask;
  start: Date;
  end: Date;
  name: string;
  label: string;
  color: string;
}

interface ITimelinePageState {
  displayReload: boolean;
  selectedItem?: ITimelineItem;
}

interface IOwnProps {

}

// Separate state props + dispatch props to their own interfaces.
interface IPropsFromState {
  // loading: boolean
  // selected?: TeamSelectedPayload
  // errors?: string
  updateTimelineInput: ITimeline | undefined;
  projectMap: IStringTMap<IProject>;
  timelineMap: IStringTMap<ITimeline>;
  timelineTaskMap: IStringTMap<ITimelineTask>;
  taskMap: IStringTMap<ITask>;
  prioritiesOrder: string[];
  priorityMap: IStringTMap<IPriority>;
  columnMap: IStringTMap<IColumn>;
  getBoardLoading: boolean;
  activeUserProfile: IActiveUserProfile;
  sortByTaskCodeAsc?: boolean;
  sortByPriorityAsc?: boolean;
  sortByColumnAsc?: boolean;
  groupBy?: TimelineGroupBy;
}

interface IPropsFromDispatch {
  // selectTeam: typeof selectTeam
  // clearSelected: typeof clearSelected
  setBoardProjectShortcode: typeof boardActions.setBoardProjectShortcode;
  clearBoard: typeof boardActions.clearBoard;
  getTimelineRequestByShortcode: typeof timelineActions.getTimelineRequestByShortcode;
  setCurrentTimelineTasksOrder: typeof timelineActions.setCurrentTimelineTasksOrder;
  updateTimelineTaskRequest: typeof timelineActions.updateTimelineTaskRequest;
}

interface IRouteParams {
  id: string;
}

// Combine both state + dispatch props - as well as any props we want to pass - in a union type.
type AllProps =
  IOwnProps &
  IPropsFromState &
  IPropsFromDispatch &
  RouteComponentProps<IRouteParams> &
  IConnectedReduxProps;

class TimelinePage extends React.PureComponent<AllProps, ITimelinePageState> {
  public state: ITimelinePageState = {
    displayReload: _.isEmpty(this.props.activeUserProfile.id),
  };

  public componentDidMount() {
    this.loadTimeline();
  }

  public componentDidUpdate(prevProps: Readonly<AllProps>) {
    // Reload the board if the previous profile got nothing but now we got authentication information
    if (_.isEmpty(prevProps.activeUserProfile.id) &&
      !_.isEmpty(this.props.activeUserProfile.id)) {
      this.loadTimeline();
      this.setState({
        displayReload: false,
      });
    }
  }

  public componentWillUnmount() {
    // clear selected team state before leaving the page
    // this.props.clearSelected()
  }

  public render() {
    const d1 = new Date();
    const d2 = new Date();
    d2.setDate(d2.getDate() + 5);
    const d3 = new Date();
    d3.setDate(d3.getDate() + 8);
    const d4 = new Date();
    d4.setDate(d4.getDate() + 20);
    // const data = [
    //   {
    //     id: 1,
    //     start: d1,
    //     end: d2,
    //     name: "Deletemo Task 1",
    //   },
    //   {
    //     id: 2,
    //     start: d3,
    //     end: d4,
    //     name: "Demo Task 2",
    //     color: "orange",
    //   },
    // ];
    const data: ITimelineItem[] = [];

    // this.props.prioritiesOrder.forEach((eachPriorityID: string) => {
    //     const eachPriority = this.props.priorityMap[eachPriorityID];
    //     if (eachPriority !== undefined) {
    //       eachPriority.taskIDs.forEach((eachTaskID: string) => {
    //         const theTask = this.props.taskMap[eachTaskID];
    //         if (theTask !== undefined) {
    //           data.push({
    //             id: theTask.id,
    //             start: getDateFromUTCEpoch(theTask.createdOn),
    //             end: getDateFromUTCEpoch(theTask.createdOn + 86400),
    //             name: `${theTask.incrementcode} - ${theTask.title}`,
    //           });
    //         }
    //       });
    //     }
    // });

    if (!_.isUndefined(this.props.updateTimelineInput)) {
      const theTimeline: ITimeline = this.props.timelineMap[this.props.updateTimelineInput.id];
      if (!_.isUndefined(theTimeline)) {
        theTimeline.timelineTasks
        .sort((a: ITimelineTask, b: ITimelineTask) => { // Task code sort
          if (this.props.sortByTaskCodeAsc === true) {
            return a.task.incrementcode - b.task.incrementcode;
          } else if (this.props.sortByTaskCodeAsc === false) {
            return b.task.incrementcode - a.task.incrementcode;
          }
          return a.sortOrder - b.sortOrder;
        })
        .sort((a: ITimelineTask, b: ITimelineTask) => { // Priority sort
          if (!_.isUndefined(this.props.sortByPriorityAsc)) {
            const priorityA: IPriority = this.props.priorityMap[a.task.priorityID];
            const priorityB: IPriority = this.props.priorityMap[b.task.priorityID];
            if (!_.isUndefined(priorityA) && !_.isUndefined(priorityB)) {
              if (this.props.sortByPriorityAsc === true) {
                return (_.isUndefined(priorityA.sortOrder) ? 0 : priorityA.sortOrder!) -
                      (_.isUndefined(priorityB.sortOrder) ? 0 : priorityB.sortOrder!);
              } else if (this.props.sortByPriorityAsc === false) {
                return (_.isUndefined(priorityB.sortOrder) ? 0 : priorityB.sortOrder!) -
                      (_.isUndefined(priorityA.sortOrder) ? 0 : priorityA.sortOrder!);
              }
            }
          }
          return 0;
        })
        .sort((a: ITimelineTask, b: ITimelineTask) => { // Column sort
          if (!_.isUndefined(this.props.sortByColumnAsc)) {
            const columnA: IColumn = this.props.columnMap[a.task.columnID];
            const columnB: IColumn = this.props.columnMap[b.task.columnID];
            if (!_.isUndefined(columnA) && !_.isUndefined(columnB)) {
              if (this.props.sortByColumnAsc === true) {
                return (_.isUndefined(columnA.sortOrder) ? 0 : columnA.sortOrder!) -
                      (_.isUndefined(columnB.sortOrder) ? 0 : columnB.sortOrder!);
              } else if (this.props.sortByColumnAsc === false) {
                return (_.isUndefined(columnB.sortOrder) ? 0 : columnB.sortOrder!) -
                      (_.isUndefined(columnA.sortOrder) ? 0 : columnA.sortOrder!);
              }
            }
          }
          return 0;
        })
        .forEach((tmpEachTimelineTask: ITimelineTask) => {
          const eachTimelineTask: ITimelineTask = this.props.timelineTaskMap[tmpEachTimelineTask.id];
          if (!eachTimelineTask.isActive) { return; }
          const priority: IPriority = this.props.priorityMap[tmpEachTimelineTask.task.priorityID];
          const column: IColumn = this.props.columnMap[tmpEachTimelineTask.task.columnID];
          const project: IProject | undefined = this.props.projectMap[tmpEachTimelineTask.task.projectID];
          data.push({
            id: eachTimelineTask.id,
            taskID: eachTimelineTask.taskID,
            timelineID: eachTimelineTask.timelineID,
            timelineTask: eachTimelineTask,
            projectShortcode: _.isUndefined(project) ? "" : project.shortcode,
            start: getDateFromUTCEpoch(eachTimelineTask.timelineStart),
            end: getDateFromUTCEpoch(
              eachTimelineTask.timelineEnd - eachTimelineTask.timelineStart > 86400 ?
              eachTimelineTask.timelineEnd :
              eachTimelineTask.timelineEnd + 86400, // Minimum display 1 day so that we can see something
            ),
            name: `${eachTimelineTask.task.incrementcode} - ${eachTimelineTask.task.title}`,
            label: `${eachTimelineTask.task.incrementcode}` +
                  ` - ` +
                  `${_.isUndefined(column) ? "Unknown" : column.title}` +
                  ` - ` +
                  `${_.isUndefined(priority) ? "Unknown" : priority.title}`,
            color: _.isUndefined(priority) ? `` :
            `rgba(
              ${priority.backgroundColor.red},
              ${priority.backgroundColor.green},
              ${priority.backgroundColor.blue},
              ${priority.backgroundColor.alpha})`,
          });
        });
      }
    }

    // this.props.currentTimelineTasksOrder.forEach((eachTaskID: string) => {
    //   const theTask = this.props.taskMap[eachTaskID];
    //   if (theTask !== undefined) {
    //     data.push({
    //       id: theTask.id,
    //       start: getDateFromUTCEpoch(theTask.createdOn),
    //       end: getDateFromUTCEpoch(theTask.createdOn + 86400),
    //       name: `${theTask.incrementcode} - ${theTask.title}`,
    //     });
    //   }
    // });

    // const links = [{ id: 1, start: 1, end: 2 }];
    const links = [];
    const config = {
      taskList: {
        title: {
          label: "Tasks",
        },
        task: {
          style: {
            whiteSpace: "nowrap",
            textAlign: "left",
            overflow: "hidden",
            textOverflow: "ellipsis",
            paddingLeft: "5px",
          },
        },
      },
      dataViewPort: {
        rows: {
          style: {
            backgroundColor: "white",
            borderBottom: "solid 0.5px silver",
          },
        },
        task: {
          showLabel: true,
          style: {
            // borderRadius: 1,
            // boxShadow: "2px 2px 8px #888888",
            overflow: "hidden",
            whiteSpace: "nowrap",
          },
          selectedStyle: {
            border: "1px solid #000000",
          },
        },
      },
    };

    return (
      this.state.displayReload ?
      (
        <Button
          intent={Intent.PRIMARY}
          text="Authenticating..."
          disabled={true}
          style={{
            // whiteSpace: "nowrap",
            // paddingLeft: "5px",
          }}
        />
      ) :
      _.isUndefined(this.props.updateTimelineInput) ?
      (
        <LoadingContainer>
            <Spinner
            />
        </LoadingContainer>
      ) :
      (
        <TimeLine
          data={data}
          links={links}
          config={config}
          mode={
            _.isUndefined(this.props.groupBy) ?
            "month" : this.props.groupBy} // The possible values are: "month","week","day","year"
          itemheight={30}
          selectedItem={this.state.selectedItem}
          onSelectItem={this.onSelectItem}
          onUpdateTask={this.onUpdateTask}
          onResetItem={this.onResetItem}
          onDeleteItem={this.onDeleteItem}
          nonEditableName={true}
        />
      )
    );
  }

  private loadTimeline() {
    const { match } = this.props;
    if (_.isUndefined(this.props.updateTimelineInput)  ||
        this.props.updateTimelineInput.shortcode !== match.params.id) {
      this.props.getTimelineRequestByShortcode(match.params.id, true);
    }
  }

  private onSelectItem = (item: ITimelineItem) => {
    console.log(`Select Item ${item.name}`);
    this.setState({ selectedItem: item });
  }

  private onUpdateTask = (item: ITimelineItem, props: ITimelineItem) => {
    // item.start = props.start ? props.start : item.start;
    // item.end = props.end ? props.end : item.end;
    // item.name = props.name ? props.name : item.name;
    // this.setState({ selectedItem: item });
    this.props.updateTimelineTaskRequest({
      ...item.timelineTask,
      timelineStart: getEpochSecondsOfDate(props.start ? props.start : item.start),
      timelineEnd: getEpochSecondsOfDate(props.end ? props.end : item.end),
    });
  }

  private onResetItem = (item: ITimelineItem) => {
    this.props.updateTimelineTaskRequest({
      ...item.timelineTask,
      timelineStart: 0,
      timelineEnd: 0,
    });

    delay(() => {
      const { match } = this.props;
      this.props.getTimelineRequestByShortcode(match.params.id, true);
    }, 1000);
  }

  private onDeleteItem = (item: ITimelineItem) => {
    this.props.updateTimelineTaskRequest({
      ...item.timelineTask,
      isActive: false,
    });
  }

  // onCreateLink = item => {
  //   let newLink = this.createLink(item.start, item.end);
  //   this.setState({
  //     links: [...this.state.links, newLink],
  //     selectedItem: newLink
  //   });
  // };
}

// It's usually good practice to only include one context at a time in a connected component.
// Although if necessary, you can always include multiple contexts. Just make sure to
// separate them from each other to prevent prop conflicts.
const mapStateToProps = ({ board, logins, timelines, projects }: IApplicationState) => ({
  // loading: teams.loading,
  // errors: teams.errors,
  // selected: teams.selected
  updateTimelineInput: timelines.updateTimelineInput,
  projectMap: projects.projectMap,
  timelineMap: timelines.timelineMap,
  timelineTaskMap: timelines.timelineTaskMap,
  taskMap: board.taskMap,
  prioritiesOrder: board.prioritiesOrder,
  priorityMap: board.priorityMap,
  columnMap: board.columnMap,
  getBoardLoading: board.getBoardLoading,
  activeUserProfile: logins.activeUserProfile,
  sortByTaskCodeAsc: timelines.sortByTaskCodeAsc,
  sortByPriorityAsc: timelines.sortByPriorityAsc,
  sortByColumnAsc: timelines.sortByColumnAsc,
  groupBy: timelines.groupBy,
});

// mapDispatchToProps is especially useful for constraining our actions to the connected component.
// You can access these via `this.props`.
const mapDispatchToProps = (dispatch: Dispatch) => ({
  // selectTeam: (team_id: string) => dispatch(selectTeam(team_id)),
  // clearSelected: () => dispatch(clearSelected())
  setBoardProjectShortcode: (projectShortcode: string) =>
        dispatch(boardActions.setBoardProjectShortcode(projectShortcode)),
  clearBoard: () =>
        dispatch(boardActions.clearBoard()),
  getTimelineRequestByShortcode: (timelineShortcode: string, setUpdateTimelineInput: boolean) =>
        dispatch(timelineActions.getTimelineRequestByShortcode(timelineShortcode, setUpdateTimelineInput)),
  setCurrentTimelineTasksOrder: (input: string[]) =>
        dispatch(timelineActions.setCurrentTimelineTasksOrder(input)),
  updateTimelineTaskRequest: (input: IUpdateTimelineTaskInput) =>
        dispatch(timelineActions.updateTimelineTaskRequest(input)),
});

// Now let's connect our component!
// With redux v4's improved typings, we can finally omit generics here.
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TimelinePage);
