/*!
 * Copyright 2019 CTC. All rights reserved.
 *
 * Licensed under the terms of the LICENSE file distributed with this timeline.
 */

import "@atlaskit/css-reset";
import {
    Alignment,
    Button,
    ButtonGroup,
    Callout,
    ContextMenu,
    EditableText,
    FormGroup,
    H1,
    Icon,
    IconName,
    InputGroup,
    Intent,
    Menu,
    MenuItem,
    NumericInput,
    Popover,
    Position,
    Slider,
    Spinner,
    Tooltip,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Select } from "@blueprintjs/select";
import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import styled from "styled-components";
import tasks from "../../pages/tasks";
import { IApplicationState } from "../../store";
import * as boardActions from "../../store/board/actions";
import { IBoardFilterInput } from "../../store/board/types";
import { IStandardColor, IStandardColorGroup } from "../../store/colors/types";
import { IColumn } from "../../store/columns/types";
import { IPriority } from "../../store/priorities/types";
import * as projectsActions from "../../store/projects/actions";
import { IProject } from "../../store/projects/types";
import { ISprint } from "../../store/sprints/types";
import { ITask, ITaskWithUserInfo } from "../../store/tasks/types";
import * as timelinesActions from "../../store/timelines/actions";
import {
    IAddOrRemoveTimelineTasksInput,
    ICreateTimelineInput,
    ICreateTimelineResult,
    ITimeline,
} from "../../store/timelines/types";
import * as usersActions from "../../store/users/actions";
import { IUser } from "../../store/users/types";
import { CONST_MAXIMUM_UNIT_POINTS_POSSIBLE } from "../../utils/constants";
import { IStringTMap } from "../../utils/types";
import { columnSelectProps } from "../board/column-select-item";
import { prioritySelectProps } from "../board/priority-select-item";
import UnitItemPointsField from "../board/unit-item-points-field";
import ColorsContextMenu from "../colors/colors-context-menu";
import { projectSelectProps } from "../projects/project-select-item";
import { sprintSelectProps } from "../sprints/sprint-select-item";
import TaskListPanel from "../tasks/task-list-panel";
import { taskSelectProps } from "../tasks/task-select-item";
import UserAddButton from "../useraddbutton";
import TimelineUpdateMenu from "./timeline-update-menu";

const Container = styled.div`
    // display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-left: 20px;
    padding-top: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    overflow: auto;
`;

const LoadingContainer = styled.div`
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding: 15px;
`;

const UserSelect = Select.ofType<IUser>();
const TaskSelect = Select.ofType<ITaskWithUserInfo>();
const ProjectSelect = Select.ofType<IProject>();
const SprintSelect = Select.ofType<ISprint>();
const PrioritySelect = Select.ofType<IPriority>();
const ColumnSelect = Select.ofType<IColumn>();

interface ITimelineAddPanelState {
    vertical: boolean;
    isContextMenuOpen: boolean;
    selectedProject?: IProject;
    selectedSprint?: ISprint;
    selectedPriority?: IPriority;
    selectedColumn?: IColumn;
    selectedTask?: ITaskWithUserInfo;
    sortByTaskCodeAsc?: boolean;
    taskPageStart: number;
    taskPageLimit: number;
}

interface IPropsFromState {
    createTimelineInput: ICreateTimelineInput;
    createTimelineResult: ICreateTimelineResult;
    updateTimelineInput?: ITimeline;
    usersLoading: boolean;
    usersLoaded: boolean;
    users: IUser[];
    userMap: IStringTMap<IUser>;
    taskMap: IStringTMap<ITask>;
    projectMap: IStringTMap<IProject>;
    projectsLoading: boolean;
    sprintMap: IStringTMap<ISprint>;
    priorityMap: IStringTMap<IPriority>;
    columnMap: IStringTMap<IColumn>;
    getBoardLoading: boolean;
    loadedSprintID?: string;
    colorgroups: IStandardColorGroup[];
    projectsOrder: string[];
    prioritiesOrder: string[];
    sprintsOrder: string[];
    columnsOrder: string[];
    boardFilter: IBoardFilterInput;
}

interface IPropsFromDispatch {
    createTimelineSetInput: typeof timelinesActions.createTimelineSetInput;
    createTimelineSetResult: typeof timelinesActions.createTimelineSetResult;
    getProjectsRequest: typeof projectsActions.getProjectsRequest;
    getBoardRequest: typeof boardActions.getBoardRequest;
    getUsers: typeof usersActions.getUsers;
    setCurrentTimelineTasksOrder: typeof timelinesActions.setCurrentTimelineTasksOrder;
    addOrRemoveTimelineTasksRequest: typeof timelinesActions.addOrRemoveTimelineTasksRequest;
}

interface IOwnProps {

}

// Combine both state + dispatch props - as well as any props we want to pass - in a union type.
type AllProps = IPropsFromState & IPropsFromDispatch & IOwnProps;

class TimelineAddTasksPanel extends React.PureComponent<AllProps, ITimelineAddPanelState> {
    public state: ITimelineAddPanelState = {
        vertical: false,
        isContextMenuOpen: false,
        taskPageStart: 0,
        taskPageLimit: 10,
        sortByTaskCodeAsc: undefined,
    };

    constructor(props) {
        super(props);
    }

    public componentDidMount() {
        if (!this.props.projectsLoading) {
            this.props.getProjectsRequest();
        }
    }

    public render() {
        const projects: IProject[] =
        this.props.projectsOrder.map((projectId, index) => {
            const project = this.props.projectMap[projectId];
            return project;
        });

        const sprints: ISprint[] =
            this.props.sprintsOrder.map((sprintId, index) => {
                const sprint = this.props.sprintMap[sprintId];
                return sprint;
            });

        const priorities: IPriority[] =
            this.props.prioritiesOrder.map((priorityId, index) => {
                const priority = this.props.priorityMap[priorityId];
                return priority;
            });

        const columns: IColumn[] =
            this.props.columnsOrder.map((columnId, index) => {
                const column = this.props.columnMap[columnId];
                return column;
            });

        const defaultSprint: ISprint | undefined =
            _.isUndefined(this.props.loadedSprintID) ?
            undefined :
            this.props.sprintMap[this.props.loadedSprintID];

        const tasksOrder: string[] =
            _.values(this.props.taskMap).filter((task: ITask) => {
                if (!_.isUndefined(this.state.selectedProject)) {
                    const matchProject: boolean = task.projectID === this.state.selectedProject.id;
                    let matchPriority: boolean = _.isUndefined(this.state.selectedPriority);
                    let matchColumn: boolean = _.isUndefined(this.state.selectedColumn);
                    let matchTask: boolean = _.isUndefined(this.state.selectedTask);
                    if (!_.isUndefined(this.state.selectedPriority)) {
                        matchPriority = task.priorityID === this.state.selectedPriority.id;
                    }
                    if (!_.isUndefined(this.state.selectedColumn)) {
                        matchColumn = task.columnID === this.state.selectedColumn.id;
                    }
                    if (!_.isUndefined(this.state.selectedTask)) {
                        matchTask = task.id === this.state.selectedTask.task.id;
                    }
                    return matchProject && matchPriority && matchColumn && matchTask;
                }
                return false;
            }).sort((a: ITask, b: ITask) => {
                if (this.state.sortByTaskCodeAsc === true) {
                    return a.incrementcode - b.incrementcode;
                } else if (this.state.sortByTaskCodeAsc === false) {
                    return b.incrementcode - a.incrementcode;
                }
                return 0;
            }).map((task: ITask) => {
                return task.id;
            });

        // Construct list of tasks with user info
        const taskWithUserInfos: ITaskWithUserInfo[] =
        tasksOrder.map((taskID: string) => {
            const task = this.props.taskMap[taskID];
            const taskWithUserInfo: ITaskWithUserInfo = {
                task,
                createdBy: this.props.userMap[task.createdByUserID],
            };
            return taskWithUserInfo;
        });
        // _.values(this.props.taskMap).filter((task: ITask) => {
        //     return tasksOrder.indexOf(task.id) !== -1;
        // }).map((task: ITask) => {
        //     const taskWithUserInfo: ITaskWithUserInfo = {
        //         task,
        //         createdBy: this.props.userMap[task.createdByUserID],
        //     };
        //     return taskWithUserInfo;
        // });

        const totalNoOfTasks: number = tasksOrder.length;
        const reducedTasksOrder: string[] = tasksOrder.splice(0, this.state.taskPageStart + this.state.taskPageLimit);
        const shouldLoadMore: boolean = reducedTasksOrder.length < totalNoOfTasks;

        // this.props.setCurrentTimelineTasksOrder(tasksOrder); // CONTINUE HERE

        return (
            <Container>
                {this.props.createTimelineResult.errors === undefined ?
                    (<div/>) :
                    (<Callout
                        intent={Intent.DANGER}
                        style={{marginBottom: "20px"}}
                    >
                        Can not create your timeline. Error: {this.props.createTimelineResult.errors}
                    </Callout>
                    )
                }
                {this.props.createTimelineResult.id === undefined ?
                    (
                        <div>
                            <FormGroup
                                // helperText="Helper text with details..."
                                label="Filter"
                                labelFor="text-input"
                                // labelInfo="(required)"
                            >
                                <ButtonGroup
                                    alignText={Alignment.CENTER}
                                    fill={false}
                                    large={false}
                                    minimal={false}
                                    style={{
                                        minWidth: 120,
                                    }}
                                >
                                    <ProjectSelect
                                        {...projectSelectProps}
                                        items={projects}
                                        // {...flags}
                                        filterable={true}
                                        disabled={this.props.projectsLoading}
                                        activeItem={null}
                                        // isItemDisabled={false}
                                        // initialContent={undefined}
                                        noResults={<MenuItem disabled={true} text="No results." />}
                                        onItemSelect={this.onSelectProject}
                                        popoverProps={{
                                            minimal: true,
                                            usePortal: true,
                                            autoFocus: true,
                                            enforceFocus: true,
                                            position: "auto",
                                            boundary: "viewport",
                                        }}
                                    >
                                        <Tooltip
                                            position={Position.BOTTOM_RIGHT}
                                            content="Select project to filter tasks"
                                        >
                                            <Button
                                                icon={IconNames.PROJECTS}
                                                text={
                                                    _.isUndefined(this.state.selectedProject) ?
                                                    "Select project..." : this.state.selectedProject!.name
                                                }
                                                loading={this.props.projectsLoading}
                                                // disabled={true}
                                                intent={Intent.NONE}
                                                // onClick={this.handleRefreshBoard}
                                                minimal={false}
                                                style={{
                                                    minWidth: 150,
                                                }}
                                            />
                                        </Tooltip>
                                    </ProjectSelect>
                                    <SprintSelect
                                        {...sprintSelectProps}
                                        items={sprints}
                                        // {...flags}
                                        filterable={true}
                                        disabled={_.isUndefined(this.state.selectedProject)}
                                        activeItem={null}
                                        // isItemDisabled={false}
                                        // initialContent={undefined}
                                        noResults={<MenuItem disabled={true} text="No results." />}
                                        onItemSelect={this.onSelectSprint}
                                        popoverProps={{
                                            minimal: true,
                                            usePortal: true,
                                            autoFocus: true,
                                            enforceFocus: true,
                                            position: "auto",
                                            boundary: "viewport",
                                        }}
                                    >
                                        <Tooltip
                                            position={Position.BOTTOM_RIGHT}
                                            content="Select sprint to filter tasks"
                                            disabled={_.isUndefined(this.state.selectedProject)}
                                        >
                                            <Button
                                                icon={IconNames.TAKE_ACTION}
                                                text={
                                                    _.isUndefined(this.state.selectedSprint) ?
                                                    (
                                                        _.isUndefined(defaultSprint) ?
                                                        "Select sprint..." :
                                                        defaultSprint.name
                                                    ) : this.state.selectedSprint!.name
                                                }
                                                disabled={_.isUndefined(this.state.selectedProject)}
                                                loading={
                                                    !_.isUndefined(this.state.selectedProject)
                                                    && this.props.getBoardLoading
                                                }
                                                // disabled={true}
                                                intent={Intent.NONE}
                                                // onClick={this.handleRefreshBoard}
                                                minimal={false}
                                                style={{
                                                    minWidth: 150,
                                                }}
                                            />
                                        </Tooltip>
                                    </SprintSelect>
                                    <PrioritySelect
                                        {...prioritySelectProps}
                                        items={priorities}
                                        // {...flags}
                                        filterable={true}
                                        disabled={_.isUndefined(this.state.selectedProject)}
                                        activeItem={null}
                                        // isItemDisabled={false}
                                        // initialContent={undefined}
                                        noResults={<MenuItem disabled={true} text="No results." />}
                                        onItemSelect={this.onSelectPriority}
                                        popoverProps={{
                                            minimal: true,
                                            usePortal: true,
                                            autoFocus: true,
                                            enforceFocus: true,
                                            position: "auto",
                                            boundary: "viewport",
                                        }}
                                    >
                                        <Tooltip
                                            position={Position.BOTTOM_RIGHT}
                                            content="Select priority to filter tasks"
                                            disabled={_.isUndefined(this.state.selectedProject)}
                                        >
                                            <Button
                                                icon={IconNames.STAR}
                                                text={
                                                    _.isUndefined(this.state.selectedPriority) ?
                                                    "Select priority..." : this.state.selectedPriority.title
                                                }
                                                disabled={_.isUndefined(this.state.selectedProject)}
                                                loading={
                                                    !_.isUndefined(this.state.selectedProject)
                                                    && this.props.getBoardLoading
                                                }
                                                // disabled={true}
                                                intent={Intent.NONE}
                                                // onClick={this.handleRefreshBoard}
                                                minimal={false}
                                                style={{
                                                    minWidth: 150,
                                                }}
                                            />
                                        </Tooltip>
                                    </PrioritySelect>
                                    <ColumnSelect
                                        {...columnSelectProps}
                                        items={columns}
                                        // {...flags}
                                        filterable={true}
                                        disabled={_.isUndefined(this.state.selectedProject)}
                                        activeItem={null}
                                        // isItemDisabled={false}
                                        // initialContent={undefined}
                                        noResults={<MenuItem disabled={true} text="No results." />}
                                        onItemSelect={this.onSelectColumn}
                                        popoverProps={{
                                            minimal: true,
                                            usePortal: true,
                                            autoFocus: true,
                                            enforceFocus: true,
                                            position: "auto",
                                            boundary: "viewport",
                                        }}
                                    >
                                        <Tooltip
                                            position={Position.BOTTOM_RIGHT}
                                            content="Select column to filter tasks"
                                            disabled={_.isUndefined(this.state.selectedProject)}
                                        >
                                            <Button
                                                icon={IconNames.TWO_COLUMNS}
                                                text={
                                                    _.isUndefined(this.state.selectedColumn) ?
                                                    "Select column..." : this.state.selectedColumn.title
                                                }
                                                disabled={_.isUndefined(this.state.selectedProject)}
                                                loading={
                                                    !_.isUndefined(this.state.selectedProject)
                                                    && this.props.getBoardLoading
                                                }
                                                // disabled={true}
                                                intent={Intent.NONE}
                                                // onClick={this.handleRefreshBoard}
                                                minimal={false}
                                                style={{
                                                    minWidth: 150,
                                                }}
                                            />
                                        </Tooltip>
                                    </ColumnSelect>
                                    <TaskSelect
                                        {...taskSelectProps}
                                        items={taskWithUserInfos}
                                        // {...flags}
                                        filterable={true}
                                        disabled={_.isUndefined(this.state.selectedProject)}
                                        activeItem={null}
                                        // isItemDisabled={false}
                                        // initialContent={undefined}
                                        noResults={<MenuItem disabled={true} text="No results." />}
                                        onItemSelect={this.onSelectTask}
                                        popoverProps={{
                                            minimal: true,
                                            usePortal: true,
                                            autoFocus: true,
                                            enforceFocus: true,
                                            position: "auto",
                                            boundary: "viewport",
                                        }}
                                    >
                                        <Tooltip
                                            position={Position.BOTTOM_RIGHT}
                                            content="Select task to filter"
                                            disabled={_.isUndefined(this.state.selectedProject)}
                                        >
                                            <Button
                                                icon={IconNames.TINT}
                                                text={
                                                    _.isUndefined(this.state.selectedTask) ?
                                                    "Select task..." : this.state.selectedTask.task.title
                                                }
                                                disabled={_.isUndefined(this.state.selectedProject)}
                                                loading={
                                                    !_.isUndefined(this.state.selectedProject)
                                                    && this.props.getBoardLoading
                                                }
                                                // disabled={true}
                                                intent={Intent.NONE}
                                                // onClick={this.handleRefreshBoard}
                                                minimal={false}
                                                style={{
                                                    minWidth: 150,
                                                }}
                                            />
                                        </Tooltip>
                                    </TaskSelect>
                                    {/* {this.renderButton("Project", IconNames.PROJECTS)}
                                    {this.renderButton("Priorities", IconNames.STAR)}
                                    {this.renderButton("Columns", IconNames.COLUMN_LAYOUT)} */}
                                </ButtonGroup>
                            </FormGroup>
                            <FormGroup
                                // helperText="Helper text with details..."
                                label="Actions"
                                labelFor="text-input"
                                // labelInfo="(required)"
                                disabled={_.isUndefined(this.state.selectedProject)}
                            >
                                <ButtonGroup>
                                    <Button
                                        icon={IconNames.ADD}
                                        text={
                                            "Add all tasks to timeline"
                                        }
                                        disabled={_.isUndefined(this.state.selectedProject)}
                                        loading={
                                            !_.isUndefined(this.state.selectedProject)
                                            && this.props.getBoardLoading
                                        }
                                        intent={Intent.PRIMARY}
                                        minimal={false}
                                        style={{
                                            minWidth: 150,
                                        }}
                                        onClick={() => {
                                            this.onAddAllTasks(taskWithUserInfos);
                                        }}
                                    />
                                    <Button
                                        icon={IconNames.SORT_NUMERICAL}
                                        text={
                                            "Ascending sort by task code"
                                        }
                                        disabled={_.isUndefined(this.state.selectedProject)}
                                        loading={
                                            !_.isUndefined(this.state.selectedProject)
                                            && this.props.getBoardLoading
                                        }
                                        intent={Intent.PRIMARY}
                                        minimal={false}
                                        style={{
                                            minWidth: 150,
                                        }}
                                        onClick={() => {
                                            this.sortByTaskCode(true);
                                        }}
                                    />
                                    <Button
                                        icon={IconNames.SORT_NUMERICAL_DESC}
                                        text={
                                            "Descending sort by task code"
                                        }
                                        disabled={_.isUndefined(this.state.selectedProject)}
                                        loading={
                                            !_.isUndefined(this.state.selectedProject)
                                            && this.props.getBoardLoading
                                        }
                                        intent={Intent.PRIMARY}
                                        minimal={false}
                                        style={{
                                            minWidth: 150,
                                        }}
                                        onClick={() => {
                                            this.sortByTaskCode(false);
                                        }}
                                    />
                                </ButtonGroup>
                            </FormGroup>
                            <FormGroup
                                // helperText="Helper text with details..."
                                label="Tasks"
                                labelFor="text-input"
                                // labelInfo="(required)"
                                disabled={_.isUndefined(this.state.selectedProject)}
                            >
                                {this.props.getBoardLoading && !_.isUndefined(this.state.selectedProject) ?
                                (
                                    <LoadingContainer>
                                        <Spinner
                                        />
                                    </LoadingContainer>
                                ) :
                                (
                                    <TaskListPanel
                                        allowAddNewTask={false}
                                        tasksOrder={reducedTasksOrder}
                                        onAddAction={this.onAddTaskActionClick}
                                        menuButtonAddText={"Add to timeline"}
                                    />
                                )}
                                {shouldLoadMore ?
                                    <Button
                                        style={{
                                            width: "100%",
                                        }}
                                        text="Load more..."
                                        minimal={true}
                                        loading={this.props.getBoardLoading}
                                        onClick={this.handleLoadMoreTasks}
                                    /> : null
                                }
                            </FormGroup>
                        </div>
                    ) :
                    (<Callout
                        intent={Intent.SUCCESS}
                    >
                        Timeline is created successfully
                    </Callout>
                    )
                }
            </Container>
        );
    }

    private renderButton = (text: string, iconName: IconName) => {
        const { vertical } = this.state;
        const rightIconName: IconName = vertical ? "caret-right" : "caret-down";
        const position = vertical ? Position.RIGHT_TOP : Position.BOTTOM_LEFT;
        return (
            <Popover content={<TimelineUpdateMenu />} position={position}>
                <Button rightIcon={rightIconName} icon={iconName} text={text} />
            </Popover>
        );
    }

    private showContextMenu = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        // must prevent default to cancel parent's context menu
        e.preventDefault();
        // invoke static API, getting coordinates from mouse event
        ContextMenu.show(
            <ColorsContextMenu
                colorgroups={this.props.colorgroups}
                onSelectColor={(selectedColor: IStandardColor) => {
                    this.props.createTimelineSetInput({
                        ...this.props.createTimelineInput,
                        color: selectedColor,
                    });
                }}
            />,
            { left: e.clientX, top: e.clientY },
            () => this.setState({ isContextMenuOpen: false }),
        );
        // indicate that context menu is open so we can add a CSS class to this element
        this.setState({ isContextMenuOpen: true });
    }

    private onSelectProject = (selectedProject?: IProject) => {
        this.setState({
            selectedProject,
            selectedSprint: undefined,
            selectedColumn: undefined,
            selectedPriority: undefined,
            selectedTask: undefined,
            taskPageStart: 0,
        });
        if (!_.isUndefined(selectedProject)) {
            this.props.getBoardRequest({
                ...this.props.boardFilter,
                shortcode: selectedProject.shortcode,
            });
        }
        // this.props.updateTaskSetInput({
        //     ...selectedTaskWithUserInfo.task,
        //     projectID: selectedTaskWithUserInfo.task.projectID,
        //     sprintID: selectedTaskWithUserInfo.task.sprintID,
        //     description: "", // To do
        // });
        // this.props.openEditTaskDialog(true);
    }

    private onSelectSprint = (selectedSprint?: ISprint) => {
        this.setState({
            selectedSprint,
            taskPageStart: 0,
        });
        if (!_.isUndefined(selectedSprint) && !_.isUndefined(this.state.selectedProject)) {
            this.props.getBoardRequest({
                ...this.props.boardFilter,
                shortcode: this.state.selectedProject.shortcode,
                sprintID: selectedSprint.id,
            });
        }
    }

    private onSelectPriority = (selectedPriority?: IPriority) => {
        this.setState({
            selectedPriority,
            taskPageStart: 0,
        });
    }

    private onSelectColumn = (selectedColumn?: IColumn) => {
        this.setState({
            selectedColumn,
            taskPageStart: 0,
        });
    }

    private onSelectTask = (selectedTask?: ITaskWithUserInfo) => {
        this.setState({
            selectedTask,
            taskPageStart: 0,
        });
    }

    private handleLoadMoreTasks = () => {
        this.setState({
            taskPageStart: this.state.taskPageStart + this.state.taskPageLimit,
        });
    }

    private onAddTaskActionClick = (task: ITask) => {
        if (!_.isUndefined(this.props.updateTimelineInput)) {
            this.props.addOrRemoveTimelineTasksRequest({
                id: this.props.updateTimelineInput.id,
                isAdd: true,
                taskIDs: [task.id],
            });
        }
    }

    private onAddAllTasks = (allTasks: ITaskWithUserInfo[]) => {
        if (!_.isUndefined(this.props.updateTimelineInput)) {
            this.props.addOrRemoveTimelineTasksRequest({
                id: this.props.updateTimelineInput.id,
                isAdd: true,
                taskIDs: _.values(allTasks).map((eachTask: ITaskWithUserInfo) => {
                    return eachTask.task.id;
                }),
            });
        }
    }

    private sortByTaskCode = (isAsc?: boolean) => {
        this.setState({sortByTaskCodeAsc : isAsc});
    }
}

// It's usually good practice to only include one context at a time in a connected component.
// Although if necessary, you can always include multiple contexts. Just make sure to
// separate them from each other to prevent prop conflicts.
const mapStateToProps = ({ timelines, users, colors, board, projects}: IApplicationState) => ({
    createTimelineInput: timelines.createTimelineInput,
    createTimelineResult: timelines.createTimelineResult,
    updateTimelineInput: timelines.updateTimelineInput,
    usersLoading: users.loading,
    usersLoaded: users.loaded,
    users: users.result.users,
    userMap: users.userMap,
    taskMap: board.taskMap,
    sprintsOrder: board.sprintsOrder,
    projectsOrder: projects.projectsOrder,
    projectMap: projects.projectMap,
    projectsLoading: projects.projectsLoading,
    sprintMap: board.sprintMap,
    priorityMap: board.priorityMap,
    columnMap: board.columnMap,
    prioritiesOrder: board.prioritiesOrder,
    columnsOrder: board.columnsOrder,
    getBoardLoading: board.getBoardLoading,
    loadedSprintID: board.loadedSprintID,
    colorgroups: colors.colorgroups,
    boardFilter: board.boardFilter,
});

// Mapping our action dispatcher to props is especially useful when creating container components.
const mapDispatchToProps = (dispatch: Dispatch) => ({
    createTimelineSetInput: (input: ICreateTimelineInput) =>
        dispatch(timelinesActions.createTimelineSetInput(input)),
    createTimelineSetResult: (result: ICreateTimelineResult) =>
        dispatch(timelinesActions.createTimelineSetResult(result)),
    getUsers: () =>
        dispatch(usersActions.getUsers()),
    getProjectsRequest: () =>
        dispatch(projectsActions.getProjectsRequest()),
    getBoardRequest: (input: IBoardFilterInput) =>
        dispatch(boardActions.getBoardRequest(input)),
    setCurrentTimelineTasksOrder: (input: string[]) =>
        dispatch(timelinesActions.setCurrentTimelineTasksOrder(input)),
    addOrRemoveTimelineTasksRequest: (input: IAddOrRemoveTimelineTasksInput) =>
        dispatch(timelinesActions.addOrRemoveTimelineTasksRequest(input)),
});

// Now let's connect our component!
// With redux v4's improved typings, we can finally omit generics here.
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TimelineAddTasksPanel);
