import React from 'react';
import createBlockAlignmentButton from '../../utils/createBlockAlignmentButton';
// import { Icon } from '@blueprintjs/core';

export default createBlockAlignmentButton({
  alignment: 'right',
  children: (
    <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <path d="M9,15 L3,15 L3,17 L9,17 L9,15 Z M9,7 L3,7 L3,9 L9,9 L9,7 Z M3,13 L9,13 L9,11 L3,11 L3,13 Z M3,21 L21,21 L21,19 L3,19 L3,21 Z M3,3 L3,5 L21,5 L21,3 L3,3 Z M11,7 L11,17 L21,17 L21,7 L11,7 Z" />
      <path d="M0 0h24v24H0z" fill="none" />
    </svg>
  ),
});
