/*!
 * Copyright 2019 CTC. All rights reserved.
 *
 * Licensed under the terms of the LICENSE file distributed with this project.
 */

import { IStandardColor } from "../colors/types";

// Use `const enum`s for better autocompletion of action type names. These will
// be compiled away leaving only the final value in your compiled code.
//
// Define however naming conventions you'd like for your action types, but
// personally, I use the `@@context/ACTION_TYPE` convention, to follow the convention
// of Redux's `@@INIT` action.
export enum LabelsActionTypes {
    SET_ADD_LABEL_STATE = "@@labels/SET_ADD_LABEL_STATE",
    SET_EDIT_LABEL_STATE = "@@labels/SET_EDIT_LABEL_STATE",
    CREATE_LABEL_SET_INPUT = "@@labels/CREATE_LABEL_SET_INPUT",
    CREATE_LABEL_REQUEST = "@@labels/CREATE_LABEL_REQUEST",
    CREATE_LABEL_SET_ERROR = "@@labels/CREATE_LABEL_SET_ERROR",
    CREATE_LABEL_SET_RESULT = "@@labels/CREATE_LABEL_SET_RESULT",
    GET_LABEL_REQUEST = "@@board/GET_LABEL_REQUEST",
    UPDATE_LABEL_SET_INPUT = "@@labels/UPDATE_LABEL_SET_INPUT",
    UPDATE_LABEL_REQUEST = "@@labels/UPDATE_LABEL_REQUEST",
    UPDATE_LABEL_SET_ERROR = "@@labels/UPDATE_LABEL_SET_ERROR",
    UPDATE_LABEL_SET_RESULT = "@@labels/UPDATE_LABEL_SET_RESULT",
    UPDATE_LABEL_TITLE_REQUEST =  "@@labels/UPDATE_LABEL_TITLE_REQUEST",
    UPDATE_LABEL_COLOR_REQUEST = "@@labels/UPDATE_LABEL_COLOR_REQUEST",
    ADD_OR_REMOVE_LABEL_MANAGER_REQUEST = "@@labels/ADD_OR_REMOVE_LABEL_MANAGER_REQUEST",
    UPDATE_LABEL_SORT_ORDER_REQUEST = "@@labels/UPDATE_LABEL_SORT_ORDER_REQUEST",
    DELETE_LABEL_REQUEST = "@@labels/DELETE_LABEL_REQUEST",
}

export enum LabelType {
    TASK_TYPE = "TASK_TYPE",
}

export interface IAddLabelState {
    label: ILabel;
}

export interface IEditLabelState {
    label: ILabel;
}

export interface ILabelCreateInput {
    title: string;
    color: IStandardColor;
    type: LabelType;
}

export interface ILabelUpdateInput {
    id: string;
    title: string;
    managers: string[];
    projectID: string;
    taskIDs: string[];
    color: IStandardColor;
}

export interface ILabelCommonResult {
    id?: string;
    errors?: string;
}

export interface ILabelGetResult {
    label?: ILabel;
    errors?: string;
}

export interface ILabelUpdateTitleInput {
    id: string;
    title: string;
}

export interface IAddOrRemoveLabelManagerInput {
    id: string;
    managerUserID: string;
    isAdd: boolean;
}

export interface ILabelUpdateSortOrderInput {
    id: string;
    beforeLabelID: string;
    afterLabelID: string;
}

export interface ILabelDeleteInput {
    id: string;
}

export interface ILabelUpdateColorInput {
    id: string;
    color: IStandardColor;
}

export interface ILabel {
    taskIDs: string[];
    id: string;
    color: IStandardColor;
    title: string;
    managers: string[];
    sortOrder?: number;
}

export interface ILabelsState {
    readonly addLabelState: IAddLabelState;
    readonly editLabelState: IEditLabelState;
    readonly createLabelLoading: boolean;
    readonly createLabelInput: ILabelCreateInput;
    readonly createLabelResult: ILabelCommonResult;
    readonly updateLabelLoading: boolean;
    readonly updateLabelInput: ILabelUpdateInput;
    readonly updateLabelResult: ILabelCommonResult;
}
