exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".separatorStyles_separator__2p0cl {\n  display: inline-block;\n  border-right: 1px solid #ddd;\n  height: 24px;\n  margin: 0 0.5em;\n}\n", ""]);

// exports
exports.locals = {
	"separator": "separatorStyles_separator__2p0cl"
};