exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles_hashtag__2cxJo {\n  color: #5e93c5;\n}\n", ""]);

// exports
exports.locals = {
	"hashtag": "styles_hashtag__2cxJo"
};