/*!
 * Copyright 2019 CTC. All rights reserved.
 *
 * Licensed under the terms of the LICENSE file distributed with this timeline.
 */

import _ from "lodash";
import { delay } from "redux-saga";
import { all, call, fork, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { IApplicationState } from "..";
import { callApiWithAuthToken } from "../../utils/api";
import {
  CONST_COOKIE_AUTHENTICATION_TOKEN,
} from "../../utils/constants";
import { getBoardRequest, insertOrUpdateTimelineBoard } from "../board/actions";
import { IBoardFilterInput } from "../board/types";
import { insertOrUpdateProjects } from "../projects/actions";
import {
  addOrRemoveTimelineMemberRequest,
  addOrRemoveTimelineTasksRequest,
  createTimelineRequest,
  createTimelineSetError,
  createTimelineSetResult,
  deleteTimelineRequest,
  getTimelineRequest,
  getTimelineRequestByShortcode,
  getTimelineSetResult,
  getTimelinesRequest,
  getTimelinesSetError,
  getTimelinesSetResult,
  insertOrUpdateTimeline,
  updateTimelineCurrentSprintRequest,
  updateTimelineCurrentSprintSetResult,
  updateTimelineNameRequest,
  updateTimelineSetInput,
  updateTimelineSortOrderRequest,
  updateTimelineTaskRequest,
} from "./actions";
import {
  ICreateTimelineResult,
  ITimeline,
  ITimelineCommonResult,
  ITimelineGetResult,
  ITimelineTask,
  TimelinesActionTypes,
} from "./types";

const API_ENDPOINT: string = process.env.REACT_APP_TASK_RIPPLE_API!;

const getAuthToken = (state: IApplicationState) =>
  // state.cookies.hasCookies ? state.cookies.cookies!.get(CONST_COOKIE_AUTHENTICATION_TOKEN) : "";
  state.logins.result.authtoken ? state.logins.result.authtoken! : "";

const getCookies = (state: IApplicationState) =>
  state.cookies.cookies;

const getCurrentTimeline = (state: IApplicationState) =>
  state.board.timeline;

const getBoardFilter = (state: IApplicationState) =>
  state.board.boardFilter;

function* handleGetTimelines(action: ReturnType<typeof getTimelinesRequest>) {
  try {
    // To call async functions, use redux-saga's `call()`.
    const authToken = yield select(getAuthToken);
    const res = yield call(callApiWithAuthToken, "get", API_ENDPOINT, "/timeline", authToken);

    if (res.error) {
      yield put(getTimelinesSetError(res.error));
    } else {
      yield put(getTimelinesSetResult(res));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(getTimelinesSetError(err.stack!));
    } else {
      yield put(getTimelinesSetError("An unknown error occured."));
    }
  }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga
function* watchGetTimelinesRequest() {
  yield takeLatest(TimelinesActionTypes.GET_TIMELINES_REQUEST, handleGetTimelines);
}

function* handleGetTimeline(action: ReturnType<typeof getTimelineRequest>) {
  try {
    // To call async functions, use redux-saga's `call()`.
    const authToken = yield select(getAuthToken);
    const res = yield call(
      callApiWithAuthToken,
      "get",
      API_ENDPOINT,
      "/timeline/" + action.payload.timelineID,
      authToken,
    );

    if (res.error) {
      // yield put(getTimelineSetError(res.error));
    } else {
      // yield put(getTimelineSetResult(res));
      // Insert to board
      const resultData: ITimelineGetResult = res;
      if (!_.isUndefined(resultData.timeline)) {
        yield put(insertOrUpdateTimeline(resultData.timeline!));
        if (action.payload.setUpdateTimelineInput) {
          yield put(updateTimelineSetInput(resultData.timeline!));
        }
        // Also update the task map
        yield put(insertOrUpdateTimelineBoard(
          resultData.timeline,
        ));

        // Also update the project map
        yield put(insertOrUpdateProjects(
          resultData.timeline.projects,
        ));
      }
    }
  } catch (err) {
    if (err instanceof Error) {
      // yield put(getTimelineSetError(err.stack!));
    } else {
      // yield put(getTimelineSetError("An unknown error occured."));
    }
  }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga
function* watchGetTimelineRequest() {
  yield takeLatest(TimelinesActionTypes.GET_TIMELINE_REQUEST, handleGetTimeline);
}

function* handleGetTimelineByShortcode(action: ReturnType<typeof getTimelineRequestByShortcode>) {
  try {
    // To call async functions, use redux-saga's `call()`.
    const authToken = yield select(getAuthToken);
    const res = yield call(
      callApiWithAuthToken,
      "get",
      API_ENDPOINT,
      "/timeline/byshortcode/" + action.payload.timelineShortcode,
      authToken,
    );

    if (res.error) {
      // yield put(getTimelineSetError(res.error));
    } else {
      // yield put(getTimelineSetResult(res));
      // Insert to board
      const resultData: ITimelineGetResult = res;
      if (!_.isUndefined(resultData.timeline)) {
        yield put(insertOrUpdateTimeline(resultData.timeline!));
        if (action.payload.setUpdateTimelineInput) {
          yield put(updateTimelineSetInput(resultData.timeline!));
        }
        // Also update the task map
        yield put(insertOrUpdateTimelineBoard(
          resultData.timeline,
        ));

        // Also update the project map
        yield put(insertOrUpdateProjects(
          resultData.timeline.projects,
        ));
      }
    }
  } catch (err) {
    if (err instanceof Error) {
      // yield put(getTimelineSetError(err.stack!));
    } else {
      // yield put(getTimelineSetError("An unknown error occured."));
    }
  }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga
function* watchGetTimelineRequestByShortcode() {
  yield takeLatest(TimelinesActionTypes.GET_TIMELINE_REQUEST_BY_SHORTCODE, handleGetTimelineByShortcode);
}

function* handleCreateTimelineRequest(action: ReturnType<typeof createTimelineRequest>) {
  try {
    // To call async functions, use redux-saga's `call()`.
    const authToken = yield select(getAuthToken);
    const res = yield call(callApiWithAuthToken, "put", API_ENDPOINT, "/timeline", authToken, action.payload);
    const cookies = yield select(getCookies);

    const resultData: ICreateTimelineResult = res;
    yield put(createTimelineSetResult(resultData));
    // Get the timeline
    if (resultData.errors === undefined && resultData.id !== undefined) {
      yield put(getTimelineRequest(resultData.id, false));
    }

  } catch (err) {
    if (err instanceof Error) {
      yield put(createTimelineSetError(err.stack!));
    } else {
      yield put(createTimelineSetError("An unknown error occured."));
    }
  }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga
function* watchCreateTimelineRequest() {
  yield takeEvery(TimelinesActionTypes.CREATE_TIMELINE_REQUEST, handleCreateTimelineRequest);
}

function* handleUpdateTimelineNameRequest(action: ReturnType<typeof updateTimelineNameRequest>) {
  try {
    yield call(delay, 1000);  // Debouncing written by takeLatest
                              // Credit: https://gist.github.com/Calvin-Huang/698cbb954d714a41c1726d0cee1be629
    // To call async functions, use redux-saga's `call()`.
    const authToken = yield select(getAuthToken);
    const res = yield call(callApiWithAuthToken,
      "post",
      API_ENDPOINT,
      "/timeline/updatetimelinename",
      authToken,
      action.payload);

    const resultData: ITimelineCommonResult = res;
    // yield put(createTimelineSetResult(resultData));
    // // Reload the timelines
    // yield put(getTimelinesRequest());

  } catch (err) {
    if (err instanceof Error) {
      // yield put(createTimelineSetError(err.stack!));
    } else {
      // yield put(createTimelineSetError("An unknown error occured."));
    }
  }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga
function* watchUpdateTimelineNameRequest() {
  yield takeLatest(TimelinesActionTypes.UPDATE_TIMELINE_NAME_REQUEST, handleUpdateTimelineNameRequest);
}

function* handleUpdateTimelineTaskRequest(action: ReturnType<typeof updateTimelineTaskRequest>) {
  try {
    yield call(delay, 1000);  // Debouncing written by takeLatest
                              // Credit: https://gist.github.com/Calvin-Huang/698cbb954d714a41c1726d0cee1be629
    // To call async functions, use redux-saga's `call()`.
    const authToken = yield select(getAuthToken);
    const res = yield call(callApiWithAuthToken,
      "post",
      API_ENDPOINT,
      "/timeline/updatetimelinetask",
      authToken,
      action.payload);

    const resultData: ITimelineCommonResult = res;
    // yield put(createTimelineSetResult(resultData));
    // // Reload the timelines
    // yield put(getTimelinesRequest());

  } catch (err) {
    if (err instanceof Error) {
      // yield put(createTimelineSetError(err.stack!));
    } else {
      // yield put(createTimelineSetError("An unknown error occured."));
    }
  }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga
function* watchUpdateTimelineTaskRequest() {
  yield takeLatest(TimelinesActionTypes.UPDATE_TIMELINE_TASK_REQUEST, handleUpdateTimelineTaskRequest);
}

function* handleUpdateTimelineCurrentSprintRequest(action: ReturnType<typeof updateTimelineCurrentSprintRequest>) {
  try {
    yield call(delay, 1000);  // Debouncing written by takeLatest
                              // Credit: https://gist.github.com/Calvin-Huang/698cbb954d714a41c1726d0cee1be629
    // To call async functions, use redux-saga's `call()`.
    const authToken = yield select(getAuthToken);
    const res = yield call(callApiWithAuthToken,
      "post",
      API_ENDPOINT,
      "/timeline/updatecurrentsprint",
      authToken,
      action.payload);

    const resultData: ITimelineCommonResult = res;
    yield put(updateTimelineCurrentSprintSetResult(resultData));
    if (resultData.errors === undefined) {
      // Reload the board
      const currentTimeline: ITimeline = yield select(getCurrentTimeline);
      const boardFilter: IBoardFilterInput = yield select(getBoardFilter);
      yield put(getBoardRequest({
        ...boardFilter,
        shortcode: currentTimeline.shortcode,
      }));
    }
  } catch (err) {
    if (err instanceof Error) {
      // yield put(createTimelineSetError(err.stack!));
      yield put(updateTimelineCurrentSprintSetResult({errors: err.stack!}));
    } else {
      // yield put(createTimelineSetError("An unknown error occured."));
      yield put(updateTimelineCurrentSprintSetResult({errors: "An unknown error occured."}));
    }
  }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga
function* watchUpdateTimelineCurrentSprintRequest() {
  yield takeLatest(
    TimelinesActionTypes.UPDATE_TIMELINE_CURRENTSPRINT_REQUEST,
    handleUpdateTimelineCurrentSprintRequest,
  );
}

function* handleAddOrRemoveTimelineMemberRequest(action: ReturnType<typeof addOrRemoveTimelineMemberRequest>) {
  try {
    // yield call(delay, 1000);  // Debouncing written by takeLatest
    //                           // Credit: https://gist.github.com/Calvin-Huang/698cbb954d714a41c1726d0cee1be629
    // To call async functions, use redux-saga's `call()`.
    const authToken = yield select(getAuthToken);
    const res = yield call(callApiWithAuthToken,
      "post",
      API_ENDPOINT,
      "/timeline/addorremovemember",
      authToken,
      action.payload);

    const resultData: ITimelineCommonResult = res;
    // yield put(createTimelineSetResult(resultData));
    // // Reload the timelines
    // yield put(getTimelinesRequest());

  } catch (err) {
    if (err instanceof Error) {
      // yield put(createTimelineSetError(err.stack!));
    } else {
      // yield put(createTimelineSetError("An unknown error occured."));
    }
  }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga
function* watchAddOrRemoveTimelineMemberRequest() {
  yield takeEvery(TimelinesActionTypes.ADD_OR_REMOVE_TIMELINE_MEMBER_REQUEST, handleAddOrRemoveTimelineMemberRequest);
}

function* handleAddOrRemoveTimelineTasksRequest(action: ReturnType<typeof addOrRemoveTimelineTasksRequest>) {
  try {
    // yield call(delay, 1000);  // Debouncing written by takeLatest
    //                           // Credit: https://gist.github.com/Calvin-Huang/698cbb954d714a41c1726d0cee1be629
    // To call async functions, use redux-saga's `call()`.
    const authToken = yield select(getAuthToken);
    const res = yield call(callApiWithAuthToken,
      "post",
      API_ENDPOINT,
      "/timeline/addorremovetasks",
      authToken,
      action.payload);

    const resultData: ITimelineCommonResult = res;
    // yield put(createTimelineSetResult(resultData));
    // Reload the timelines
    if (resultData.id !== undefined) {
      yield put(getTimelineRequest(resultData.id, false));
    }
  } catch (err) {
    if (err instanceof Error) {
      // yield put(createTimelineSetError(err.stack!));
    } else {
      // yield put(createTimelineSetError("An unknown error occured."));
    }
  }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga
function* watchAddOrRemoveTimelineTasksRequest() {
  yield takeEvery(TimelinesActionTypes.ADD_OR_REMOVE_TIMELINE_TASKS_REQUEST, handleAddOrRemoveTimelineTasksRequest);
}

function* handleUpdateTimelineSortOrderRequest(action: ReturnType<typeof updateTimelineSortOrderRequest>) {
  try {
    // To call async functions, use redux-saga's `call()`.
    const authToken = yield select(getAuthToken);
    const res = yield call(callApiWithAuthToken,
      "post",
      API_ENDPOINT,
      "/timeline/updatesortorder",
      authToken,
      action.payload);

    const resultData: ITimelineCommonResult = res;
    // yield put(createTimelineSetResult(resultData));
    // // Reload the timelines
    // yield put(getTimelinesRequest());

  } catch (err) {
    if (err instanceof Error) {
      // yield put(createTimelineSetError(err.stack!));
    } else {
      // yield put(createTimelineSetError("An unknown error occured."));
    }
  }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga
function* watchUpdateTimelineSortOrderRequest() {
  yield takeEvery(TimelinesActionTypes.UPDATE_TIMELINE_SORT_ORDER_REQUEST, handleUpdateTimelineSortOrderRequest);
}

function* handleUpdateTimelineColorRequest(action: ReturnType<typeof updateTimelineNameRequest>) {
  try {
    // To call async functions, use redux-saga's `call()`.
    const authToken = yield select(getAuthToken);
    const res = yield call(callApiWithAuthToken,
      "post",
      API_ENDPOINT,
      "/timeline/updatecolor",
      authToken,
      action.payload);

    const resultData: ITimelineCommonResult = res;
    // yield put(createTimelineSetResult(resultData));
    // // Reload the timelines
    // yield put(getTimelinesRequest());

  } catch (err) {
    if (err instanceof Error) {
      // yield put(createTimelineSetError(err.stack!));
    } else {
      // yield put(createTimelineSetError("An unknown error occured."));
    }
  }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga
function* watchUpdateTimelineColorRequest() {
  yield takeLatest(TimelinesActionTypes.UPDATE_TIMELINE_COLOR_REQUEST, handleUpdateTimelineColorRequest);
}

function* handleUpdateTimelineUnitPointsRangeRequest(action: ReturnType<typeof updateTimelineNameRequest>) {
  try {
    // yield call(delay, 1000);  // Debouncing written by takeLatest
    //                           // Credit: https://gist.github.com/Calvin-Huang/698cbb954d714a41c1726d0cee1be629
    // To call async functions, use redux-saga's `call()`.
    const authToken = yield select(getAuthToken);
    const res = yield call(callApiWithAuthToken,
      "post",
      API_ENDPOINT,
      "/timeline/updateunitpointsrange",
      authToken,
      action.payload);

    const resultData: ITimelineCommonResult = res;
    // yield put(createTimelineSetResult(resultData));
    // // Reload the timelines
    // yield put(getTimelinesRequest());

  } catch (err) {
    if (err instanceof Error) {
      // yield put(createTimelineSetError(err.stack!));
    } else {
      // yield put(createTimelineSetError("An unknown error occured."));
    }
  }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga
function* watchUpdateTimelineUnitPointsRangeRequest() {
  yield takeLatest(
    TimelinesActionTypes.UPDATE_TIMELINE_UNITPOINTSRANGE_REQUEST,
    handleUpdateTimelineUnitPointsRangeRequest,
  );
}

function* handleDeleteTimelineRequest(action: ReturnType<typeof deleteTimelineRequest>) {
  try {
    // To call async functions, use redux-saga's `call()`.
    const authToken = yield select(getAuthToken);
    const res = yield call(callApiWithAuthToken,
      "delete",
      API_ENDPOINT,
      "/timeline",
      authToken,
      action.payload);

    const resultData: ITimelineCommonResult = res;
    // yield put(createTimelineSetResult(resultData));
    // // Reload the timelines
    // yield put(getTimelinesRequest());

  } catch (err) {
    if (err instanceof Error) {
      // yield put(createTimelineSetError(err.stack!));
    } else {
      // yield put(createTimelineSetError("An unknown error occured."));
    }
  }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga
function* watchDeleteTimelineRequest() {
  yield takeEvery(TimelinesActionTypes.DELETE_TIMELINE_REQUEST, handleDeleteTimelineRequest);
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* timelinesSaga() {
  yield all([
    fork(watchCreateTimelineRequest),
    fork(watchGetTimelinesRequest),
    fork(watchUpdateTimelineNameRequest),
    fork(watchAddOrRemoveTimelineMemberRequest),
    fork(watchUpdateTimelineSortOrderRequest),
    fork(watchUpdateTimelineColorRequest),
    fork(watchDeleteTimelineRequest),
    fork(watchUpdateTimelineCurrentSprintRequest),
    fork(watchUpdateTimelineUnitPointsRangeRequest),
    fork(watchGetTimelineRequest),
    fork(watchGetTimelineRequestByShortcode),
    fork(watchAddOrRemoveTimelineTasksRequest),
    fork(watchUpdateTimelineTaskRequest),
  ]);
}

export default timelinesSaga;
