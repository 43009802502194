/*!
 * Copyright 2019 CTC. All rights reserved.
 *
 * Licensed under the terms of the LICENSE file distributed with this project.
 */

import {
    Alignment,
    Button,
    Classes,
    ContextMenu,
    Icon,
    InputGroup,
    Intent,
    Menu,
    MenuDivider,
    MenuItem,
    NumericInput,
    Switch,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import { Route } from "react-router";
import { Dispatch } from "redux";
import styled from "styled-components";
import { IApplicationState } from "../../store";
import * as boardActions from "../../store/board/actions";
import { IStandardColor, IStandardColorGroup } from "../../store/colors/types";
import * as labelActions from "../../store/labels/actions";
import {
    ILabel,
    ILabelCommonResult,
    ILabelCreateInput,
    ILabelUpdateColorInput,
    ILabelUpdateSortOrderInput,
    ILabelUpdateTitleInput,
    LabelType,
} from "../../store/labels/types";
import * as navbarActions from "../../store/navbar/actions";
import { ITask } from "../../store/tasks/types";
import invert, { HexColor, IBlackWhite, IRGB, RgbArray } from "../../utils/invertcolor";
import { IStringTMap } from "../../utils/types";
import ColorsContextMenu from "../colors/colors-context-menu";
import LabelItemTitleField from "./label-item-title-field";

const Container = styled.div`
    margin-bottom: 8px;
    display: flex;
    flex-direction: row;
`;

const defaultNewLabel: ILabelCreateInput = {
    title: "",
    color: {
        alpha: 0.0,
        red: 255,
        green: 255,
        blue: 255,
    },
    type: LabelType.TASK_TYPE,
};

interface ITaskDetailPanelSelectTypeState {
    newLabel: ILabelCreateInput;
}

// Props passed from mapStateToProps
interface IPropsFromState {
    isUsingDarkTheme: boolean;
    labelMap: IStringTMap<ILabel>;
    labelsOrder: string[];
    colorgroups: IStandardColorGroup[];
    createLabelInput: ILabelCreateInput;
    createLabelLoading: boolean;
    createLabelResult: ILabelCommonResult;
}

// Props passed from mapDispatchToProps
interface IPropsFromDispatch {
    enableDarkTheme: typeof navbarActions.enableDarkTheme;
    createLabelSetInput: typeof labelActions.createLabelSetInput;
    createLabelRequest: typeof labelActions.createLabelRequest;
    updateLabelColorRequest: typeof labelActions.updateLabelColorRequest;
    updateLabelTitleRequest: typeof labelActions.updateLabelTitleRequest;
    updateLabelSortOrderRequest: typeof labelActions.updateLabelSortOrderRequest;
    setLabelsOrder: typeof boardActions.setLabelsOrder;
}

// Component-specific props.
interface IOwnProps {
    onSelectLabel: (selectedLabel: ILabel) => void;
    selectedLabelIDs: string[];
}

// Combine both state + dispatch props - as well as any props we want to pass - in a union type.
type AllProps = IPropsFromState & IPropsFromDispatch & IOwnProps;

class TaskDetailPanelSelectLabelContextMenu extends React.PureComponent<AllProps, ITaskDetailPanelSelectTypeState> {

    public state: ITaskDetailPanelSelectTypeState = {
        newLabel: {
            ...defaultNewLabel,
        },
    };

    public onToggleDarkTheme = () => {
        this.props.enableDarkTheme(!this.props.isUsingDarkTheme);
    }

    public render() {
        return (
            <Menu className={Classes.ELEVATION_1}>
                <MenuDivider title="Select type" />
                {
                    this.props.labelsOrder.map((eachLabelID: string, index: number) => {
                        let userChecked: boolean = false;
                        const label = this.props.labelMap[eachLabelID];
                        if (label !== undefined) {
                            userChecked = this.props.selectedLabelIDs.indexOf(eachLabelID) >= 0;
                            return (
                                <Container
                                    key={eachLabelID}
                                >
                                    <div>
                                        <NumericInput
                                            className="hide-numeric-input-group"
                                            allowNumericCharactersOnly={true}
                                            buttonPosition="right"
                                            disabled={false}
                                            fill={false}
                                            intent={Intent.NONE}
                                            large={false}
                                            majorStepSize={10}
                                            max={100}
                                            min={-100}
                                            minorStepSize={0.1}
                                            selectAllOnFocus={false}
                                            selectAllOnIncrement={false}
                                            stepSize={1}
                                            value={index + 1}
                                            placeholder="Index..."
                                            onValueChange={(valueAsNumber: number) => {
                                                this.onChangeLabelSortOrderInput(
                                                    label,
                                                    index - valueAsNumber < 0,
                                                );
                                            }}
                                        />
                                    </div>
                                    <LabelItemTitleField
                                        colorgroups={this.props.colorgroups}
                                        initialColor={label.color}
                                        initialTitle={label.title}
                                        onColorChange={(newValue: IStandardColor) => {
                                            this.onColorChange(label, newValue);
                                        }}
                                        onTitleChange={(newValue: string) => {
                                            this.onTitleChange(label, newValue);
                                        }}
                                    />
                                    {
                                        !userChecked ?
                                        <Button
                                            onClick={(e) => this.handleSelectLabel(label)}
                                            active={true}
                                            intent={Intent.PRIMARY}
                                            style={{
                                                maxWidth: `24px`,
                                                maxHeight: `24px`,
                                                borderRadius: "2px",
                                                overflow: "hidden",
                                                padding: "0px",
                                                marginLeft: "5px",
                                                marginRight: "0px",
                                                justifyContent: "center",
                                                display: "flex",
                                                flexDirection: "column",
                                            }}
                                        />
                                        :
                                        <Button
                                            onClick={(e) => this.handleUnselectLabel(label)}
                                            active={true}
                                            // minimal={true}
                                            icon={
                                                IconNames.TICK
                                            }
                                            // loading={_.isUndefined(this.props.userChecked.avatarBase64)}
                                            style={{
                                                maxWidth: `24px`,
                                                maxHeight: `24px`,
                                                borderRadius: "2px",
                                                overflow: "hidden",
                                                padding: "0px",
                                                marginLeft: "5px",
                                                marginRight: "0px",
                                                justifyContent: "center",
                                                display: "flex",
                                                flexDirection: "column",
                                            }}
                                        />
                                    }
                                </Container>
                                // <MenuItem
                                //     key={label.id}
                                //     text={label.title}
                                //     onClick={() => {this.props.onSelectLabel(label); }}
                                //     style={{
                                //         backgroundColor: `rgba(
                                //             ${label.backgroundColor.red},
                                //             ${label.backgroundColor.green},
                                //             ${label.backgroundColor.blue},
                                //             0.2)`,
                                //     }}
                                // />
                            );
                        } else {
                            return null;
                        }
                    })
                }
                <Container
                    key={"AddLabelContainer"}
                >
                    <div
                        style={{
                            flexGrow: 1,
                        }}
                    >
                        <InputGroup
                            style={{
                                color: `${
                                    invert(
                                    {
                                        r: this.state.newLabel.color.red,
                                        g: this.state.newLabel.color.green,
                                        b: this.state.newLabel.color.blue,
                                    })
                                }`,
                                backgroundColor: `rgba(
                                    ${this.state.newLabel.color.red},
                                    ${this.state.newLabel.color.green},
                                    ${this.state.newLabel.color.blue},
                                    ${this.state.newLabel.color.alpha})`,
                            }}
                            value={this.state.newLabel.title}
                            placeholder={"Enter your new task type..."}
                            onBlur={() => {
                                // if (this.props.onBlur !== undefined) {
                                //     this.props.onBlur();
                                // }
                            }}
                            onFocus={() => {
                                // if (this.props.onFocus !== undefined) {
                                //     this.props.onFocus();
                                // }
                            }}
                            rightElement={
                                <Button
                                    onClick={(e) => this.showNewLabelColorContextMenu(e)}
                                    active={true}
                                    // minimal={true}
                                    icon={
                                        <Icon
                                            icon={IconNames.TINT}
                                            color={
                                                `rgba(
                                                    ${this.state.newLabel.color.red},
                                                    ${this.state.newLabel.color.green},
                                                    ${this.state.newLabel.color.blue},
                                                    1.0)`
                                            }
                                        />
                                    }
                                    // loading={_.isUndefined(this.props.userChecked.avatarBase64)}
                                    style={{
                                        maxWidth: `24px`,
                                        maxHeight: `24px`,
                                        borderRadius: "2px",
                                        overflow: "hidden",
                                        padding: "0px",
                                        marginRight: "5px",
                                        justifyContent: "center",
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                />
                            }
                            onChange={(e) => {
                                this.onNewLabelTitleChange(e.target.value);
                            }}
                        />
                    </div>
                    <Button
                        onClick={this.handleAddNewTaskType}
                        active={false}
                        // minimal={true}
                        icon={
                            IconNames.ADD
                        }
                        loading={this.props.createLabelLoading === true}
                        style={{
                            maxWidth: `24px`,
                            maxHeight: `24px`,
                            borderRadius: "2px",
                            overflow: "hidden",
                            padding: "0px",
                            marginLeft: "5px",
                            marginRight: "0px",
                            justifyContent: "center",
                            display: "flex",
                            flexDirection: "column",
                        }}
                    />
                </Container>
            </Menu>
        );
    }

    private onNewLabelTitleChange = (title: string) => {
        this.setState({
            newLabel: {
                ...this.state.newLabel,
                title,
            },
        });
    }

    private handleAddNewTaskType = () => {
        this.props.createLabelRequest({
            ...this.state.newLabel,
        });
        this.setState({
            newLabel: {
                ...defaultNewLabel,
            },
        });
    }

    private showNewLabelColorContextMenu = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        // must prevent default to cancel parent's context menu
        e.preventDefault();
        // invoke static API, getting coordinates from mouse event
        ContextMenu.show(
            <ColorsContextMenu
                colorgroups={this.props.colorgroups}
                onSelectColor={(selectedColor: IStandardColor) => {
                    this.setState({
                        newLabel: {
                            ...this.state.newLabel,
                            color: selectedColor,
                        },
                    });
                }}
            />,
            { left: e.clientX, top: e.clientY },
            () => {return; },
        );
    }

    private onColorChange = (label: ILabel, newColor: IStandardColor) => {
        this.props.updateLabelColorRequest({
            id: label.id,
            color: newColor,
        });
    }

    private onTitleChange = (label: ILabel, newTitle: string) => {
        this.props.updateLabelTitleRequest({
            id: label.id,
            title: newTitle,
        });
    }
    private onChangeLabelSortOrderInput = (label: ILabel, isUp: boolean) => {
        const labelsOrder = this.props.labelsOrder;
        const currentIndex = labelsOrder.indexOf(label.id);
        let nextIndex = currentIndex;
        if (isUp) {
            nextIndex--;
            if (nextIndex < 0) {
                nextIndex = 0;
            }
        } else {
            nextIndex++;
            if (nextIndex >= labelsOrder.length) {
                nextIndex = labelsOrder.length - 1;
            }
        }

        if (currentIndex !== nextIndex) {
            // Send update request (do this before swap so that the units is sustained)
            let afterLabelID: string | undefined;
            let beforeLabelID: string | undefined;
            if (currentIndex < nextIndex) {
                afterLabelID = labelsOrder[nextIndex];
                beforeLabelID = labelsOrder[nextIndex + 1];
            } else {
                afterLabelID = labelsOrder[nextIndex - 1];
                beforeLabelID = labelsOrder[nextIndex];
            }

            // Swap the order
            const swapWith: string = labelsOrder[nextIndex];
            labelsOrder[nextIndex] = label.id;
            labelsOrder[currentIndex] = swapWith;

            this.props.setLabelsOrder(
                [...labelsOrder],
            );
            // Update the sort order
            this.props.updateLabelSortOrderRequest({
                id: label.id,
                afterLabelID,
                beforeLabelID,
            });
        }
    }

    private handleSelectLabel = (label: ILabel) => {
        this.props.onSelectLabel(label);
    }

    private handleUnselectLabel = (label: ILabel) => {
        this.props.onSelectLabel(label);
    }
}

// It's usually good practice to only include one context at a time in a connected component.
// Although if necessary, you can always include multiple contexts. Just make sure to
// separate them from each other to prevent prop conflicts.
const mapStateToProps = ({ navbar, board, colors, labels }: IApplicationState) => ({
    isUsingDarkTheme: navbar.isUsingDarkTheme,
    labelMap: board.labelMap,
    labelsOrder: board.labelsOrder,
    colorgroups: colors.colorgroups,
    createLabelInput: labels.createLabelInput,
    createLabelLoading: labels.createLabelLoading,
    createLabelResult: labels.createLabelResult,
});

// Mapping our action dispatcher to props is especially useful when creating container components.
const mapDispatchToProps = (dispatch: Dispatch) => ({
    enableDarkTheme: (enableDarkTheme: boolean) =>
        dispatch(navbarActions.enableDarkTheme(enableDarkTheme)),
    createLabelSetInput: (input: ILabelCreateInput) =>
        dispatch(labelActions.createLabelSetInput(input)),
    createLabelRequest: (input: ILabelCreateInput) =>
        dispatch(labelActions.createLabelRequest(input)),
    updateLabelSortOrderRequest: (input: ILabelUpdateSortOrderInput) =>
        dispatch(labelActions.updateLabelSortOrderRequest(input)),
    updateLabelColorRequest: (input: ILabelUpdateColorInput) =>
        dispatch(labelActions.updateLabelColorRequest(input)),
    updateLabelTitleRequest: (input: ILabelUpdateTitleInput) =>
        dispatch(labelActions.updateLabelTitleRequest(input)),
    setLabelsOrder: (labelsOrder: string[]) =>
        dispatch(boardActions.setLabelsOrder(labelsOrder)),
});

// Now let's connect our component!
// With redux v4's improved typings, we can finally omit generics here.
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TaskDetailPanelSelectLabelContextMenu);
