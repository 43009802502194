/*!
 * Copyright 2019 CTC. All rights reserved.
 *
 * Licensed under the terms of the LICENSE file distributed with this timeline.
 */

import _ from "lodash";
import { Reducer } from "redux";
import { IStringTMap } from "../../utils/types";
import { getTimelineSetResult, setCurrentTimelineTasksOrder, updateTimelineTaskRequest } from "./actions";
import {
    ADD_NEW_TIMELINE,
    IAddOrRemoveTimelineMemberInput,
    ICreateTimelineResult,
    IGetTimelinesResult,
    ITimeline,
    ITimelineDeleteInput,
    ITimelineGetResult,
    ITimelinesState,
    ITimelineTask,
    ITimelineUpdateUnitPointsRangeInput,
    TimelinesActionTypes,
} from "./types";

const initialState: ITimelinesState = {
    timelines: {
        "timeline-1": {id: "timeline-1", title: "Cooking the Books"},
        "timeline-2": {id: "timeline-2", title: "Drinking the Profit"},
        "timeline-3": {id: "timeline-3", title: "Keeping it cool"},
    },
    // Facilitate reordering of the timelines
    timelineOrder: ["timeline-1", "timeline-2", "timeline-3"],
    timelinesLoading: false,
    defaultTimelineColor: {
        alpha: 1.0,
        red: 57,
        green: 75,
        blue: 89,
    },
    createTimelineLoading: false,
    createTimelineInput: {
        name: "",
        members: [],
        color: {
            alpha: 1.0,
            red: 57,
            green: 75,
            blue: 89,
        },
    },
    createTimelineResult: {
        id: undefined,
        errors: undefined,
    },
    getTimelinesLoading: false,
    getTimelinesResult: {
        timelines: [],
    },
    timelineMap: {},
    timelinesOrder: [],
    timelineTaskMap: {},
    updateTimelineCurrentSprintLoading: false,
    updateTimelineInput: undefined,
};

const reducer: Reducer<ITimelinesState> = (state = initialState, action) => {
    switch (action.type) {
        case ADD_NEW_TIMELINE:
            const addNewTimeline = () => {
                const timelines = _.clone(state.timelines);
                const timelineOrder = _.clone(state.timelineOrder);
                // const newTimelineID = uuid
                // timelines['timeline']
                return {
                    ...state,
                };
            };
            return addNewTimeline();
        case TimelinesActionTypes.CREATE_TIMELINE_SET_INPUT:
            return {
                ...state,
                createTimelineInput: action.payload,
            };
        case TimelinesActionTypes.CREATE_TIMELINE_REQUEST:
            return {
                ...state,
                createTimelineLoading: true,
            };
        case TimelinesActionTypes.CREATE_TIMELINE_SET_ERROR:
            return {
                ...state,
                createTimelineLoading: false,
                createTimelineResult: action.payload,
            };
        case TimelinesActionTypes.CREATE_TIMELINE_SET_RESULT:
            return {
                ...state,
                createTimelineLoading: false,
                createTimelineResult: action.payload,
            };
        case TimelinesActionTypes.SET_TIMELINES_LOADING:
            return {
                ...state,
                timelinesLoading: action.payload,
            };
        case TimelinesActionTypes.GET_TIMELINES_REQUEST:
            return {
                ...state,
                getTimelinesLoading: true,
            };
        case TimelinesActionTypes.GET_TIMELINES_SET_RESULT: {
            const getTimelinesResult: IGetTimelinesResult = action.payload;
            const timelineMap = {};
            const newTimelineTaskMap: IStringTMap<ITimelineTask>  = {};
            if (getTimelinesResult.errors === undefined) {
                // Generate timelineTaskMap
                getTimelinesResult.timelines.forEach((eachTimeline: ITimeline) => {
                    eachTimeline.timelineTasks.forEach((eachTimelineTask: ITimelineTask) => {
                        newTimelineTaskMap[eachTimelineTask.id] = eachTimelineTask;
                    });
                });

                // Update timeline map
                getTimelinesResult.timelines.map((eachTimeline: ITimeline, index: number) => {
                    if (state.timelineMap[eachTimeline.id] === undefined) {
                        timelineMap[eachTimeline.id] = eachTimeline;
                    } else {
                        timelineMap[eachTimeline.id] = {
                            ...eachTimeline,
                            timelineTasks: [
                                ...state.timelineMap[eachTimeline.id].timelineTasks,
                            ],
                        };
                    }
                });
            }
            return {
                ...state,
                getTimelinesLoading: false,
                getTimelinesResult,
                timelineMap,
                timelinesOrder: _.keys(timelineMap),
                timelineTaskMap: {
                    ...state.timelineTaskMap,
                    ...newTimelineTaskMap,
                },
            };
        }
        case TimelinesActionTypes.GET_TIMELINES_SET_ERROR: {
            return {
                ...state,
                getTimelinesLoading: false,
                getTimelinesResult: action.payload,
            };
        }
        case TimelinesActionTypes.UPDATE_TIMELINE_NAME_REQUEST: {
            return {
                ...state,
                timelineMap: {
                    ...state.timelineMap,
                    [action.payload.id] : {
                        ...state.timelineMap[action.payload.id],
                        name: action.payload.name,
                    },
                },
            };
        }
        case TimelinesActionTypes.UPDATE_TIMELINE_COLOR_REQUEST: {
            return {
                ...state,
                timelineMap: {
                    ...state.timelineMap,
                    [action.payload.id] : {
                        ...state.timelineMap[action.payload.id],
                        color: action.payload.color,
                    },
                },
            };
        }
        case TimelinesActionTypes.UPDATE_TIMELINE_UNITPOINTSRANGE_REQUEST: {
            const input: ITimelineUpdateUnitPointsRangeInput = action.payload;
            return {
                ...state,
                timelineMap: {
                    ...state.timelineMap,
                    [action.payload.id] : {
                        ...state.timelineMap[action.payload.id],
                        minUnitPoints: input.minUnitPoints,
                        maxUnitPoints: input.maxUnitPoints,
                    },
                },
            };
        }
        case TimelinesActionTypes.ADD_OR_REMOVE_TIMELINE_MEMBER_REQUEST: {
            const addOrRemoveTimelineMemberInput: IAddOrRemoveTimelineMemberInput = action.payload;
            const memberUserIDs = addOrRemoveTimelineMemberInput.isAdd ?
            [
                ...state.timelineMap[addOrRemoveTimelineMemberInput.id].memberUserIDs,
                addOrRemoveTimelineMemberInput.memberUserID,
            ] :
            _.filter(state.timelineMap[addOrRemoveTimelineMemberInput.id].memberUserIDs, (eachUserID: string) => {
                return eachUserID !== addOrRemoveTimelineMemberInput.memberUserID;
            });
            return {
                ...state,
                timelineMap: {
                    ...state.timelineMap,
                    [action.payload.id] : {
                        ...state.timelineMap[action.payload.id],
                        memberUserIDs,
                    },
                },
            };
        }
        case TimelinesActionTypes.SET_TIMELINES_ORDER: {
            return {
                ...state,
                timelinesOrder: action.payload,
            };
        }
        case TimelinesActionTypes.DELETE_TIMELINE_REQUEST: {
            const deleteTimelineInput: ITimelineDeleteInput = action.payload;
            return {
                ...state,
                timelinesOrder: _.remove(state.timelinesOrder, (eachTimelineID: string) => {
                    return eachTimelineID !== deleteTimelineInput.id;
                }),
            };
        }
        case TimelinesActionTypes.UPDATE_TIMELINE_CURRENTSPRINT_REQUEST: {
            return {
                ...state,
                updateTimelineCurrentSprintLoading: true,
            };
        }
        case TimelinesActionTypes.UPDATE_TIMELINE_CURRENTSPRINT_SET_RESULT: {
            return {
                ...state,
                updateTimelineCurrentSprintLoading: false,
            };
        }
        case TimelinesActionTypes.INSERT_OR_UPDATE_TIMELINE: {
            const insertedTimeline: ITimeline = action.payload;
            let isInsertTimeline: boolean = false;
            if (state.timelinesOrder.indexOf(insertedTimeline.id) < 0) {
                isInsertTimeline = true;
            }

            // Generate timelineTaskMap
            const newTimelineTaskMap: IStringTMap<ITimelineTask>  = {};
            insertedTimeline.timelineTasks.forEach((eachTimelineTask: ITimelineTask) => {
                newTimelineTaskMap[eachTimelineTask.id] = eachTimelineTask;
            });

            return {
                ...state,
                timelineMap : {
                    ...state.timelineMap,
                    [insertedTimeline.id] : insertedTimeline,
                },
                timelinesOrder: isInsertTimeline ?
                    [...state.timelinesOrder, insertedTimeline.id] :
                    [...state.timelinesOrder],
                timelineTaskMap: {
                    ...state.timelineTaskMap,
                    ...newTimelineTaskMap,
                },
            };
        }
        case TimelinesActionTypes.UPDATE_TIMELINE_SET_INPUT: {
            const theTimeline: ITimeline = action.payload;

            // Generate timelineTaskMap
            const newTimelineTaskMap: IStringTMap<ITimelineTask>  = {};
            theTimeline.timelineTasks.forEach((eachTimelineTask: ITimelineTask) => {
                newTimelineTaskMap[eachTimelineTask.id] = eachTimelineTask;
            });

            return {
                ...state,
                timelineMap : {
                    ...state.timelineMap,
                    [theTimeline.id] : theTimeline,
                },
                timelineTaskMap: {
                    ...state.timelineTaskMap,
                    ...newTimelineTaskMap,
                },
                updateTimelineInput: theTimeline,
            };
        }
        case TimelinesActionTypes.SET_CURRENT_TIMELINE_TASKS_ORDER: {
            const actionData: ReturnType<typeof setCurrentTimelineTasksOrder> = {
                payload : action.payload,
                type: TimelinesActionTypes.SET_CURRENT_TIMELINE_TASKS_ORDER,
            };
            return {
                ...state,
                currentTimelineTasksOrder: actionData.payload,
            };
        }
        case TimelinesActionTypes.GET_TIMELINE_SET_RESULT: {
            const actionData: ReturnType<typeof getTimelineSetResult> = {
                payload : action.payload,
                type: TimelinesActionTypes.GET_TIMELINE_SET_RESULT,
            };

            // Generate timelineMap
            const newTimelineMap: IStringTMap<ITimeline>  = {};
            newTimelineMap[actionData.payload.id] = actionData.payload;

            // Generate timelineTaskMap
            const newTimelineTaskMap: IStringTMap<ITimelineTask>  = {};
            actionData.payload.timelineTasks.forEach((eachTimelineTask: ITimelineTask) => {
                newTimelineTaskMap[eachTimelineTask.id] = eachTimelineTask;
            });
            return {
                ...state,
                timelineMap: {
                    ...state.timelineMap,
                    ...newTimelineMap,
                },
                timelineTaskMap: {
                    ...state.timelineTaskMap,
                    ...newTimelineTaskMap,
                },
            };
        }
        case TimelinesActionTypes.UPDATE_TIMELINE_TASK_REQUEST: {
            const actionData: ReturnType<typeof updateTimelineTaskRequest> = {
                payload : action.payload,
                type: TimelinesActionTypes.UPDATE_TIMELINE_TASK_REQUEST,
            };

            return {
                ...state,
                timelineTaskMap: {
                    ...state.timelineTaskMap,
                    [actionData.payload.id] : {
                        ...state.timelineTaskMap[actionData.payload.id],
                        ...actionData.payload,
                    },
                },
            };
        }
        case TimelinesActionTypes.UPDATE_SORT_BY_TASK_CODE: {
            return {
                ...state,
                sortByTaskCodeAsc: action.payload,
            };
        }
        case TimelinesActionTypes.UPDATE_SORT_BY_PRIORITY: {
            return {
                ...state,
                sortByPriorityAsc: action.payload,
            };
        }
        case TimelinesActionTypes.UPDATE_SORT_BY_COLUMN: {
            return {
                ...state,
                sortByColumnAsc: action.payload,
            };
        }
        case TimelinesActionTypes.UPDATE_GROUP_BY: {
            return {
                ...state,
                groupBy: action.payload,
            };
        }
        case TimelinesActionTypes.GET_TIMELINE_REQUEST:
        case TimelinesActionTypes.GET_TIMELINE_REQUEST_BY_SHORTCODE:
        case TimelinesActionTypes.UPDATE_TIMELINE_SORT_ORDER_REQUEST:
        case TimelinesActionTypes.ADD_OR_REMOVE_TIMELINE_TASKS_REQUEST:
        default:
            return state;
    }
};

export { reducer as timelinesReducer };
