/*!
 * Copyright 2019 CTC. All rights reserved.
 *
 * Licensed under the terms of the LICENSE file distributed with this timeline.
 */

import { action } from "typesafe-actions";
import {
    ADD_NEW_TIMELINE,
    IAddOrRemoveTimelineMemberInput,
    IAddOrRemoveTimelineTasksInput,
    ICreateTimelineInput,
    ICreateTimelineResult,
    ITimeline,
    ITimelineCommonResult,
    ITimelineDeleteInput,
    ITimelineTask,
    ITimelineUpdateColorInput,
    ITimelineUpdateCurrentSprintInput,
    ITimelineUpdateSortOrderInput,
    ITimelineUpdateUnitPointsRangeInput,
    IUpdateTimelineNameInput,
    IUpdateTimelineTaskInput,
    TimelineGroupBy,
    TimelinesActionTypes,
} from "./types";

export const addNewTimeline = () => ({
    type: ADD_NEW_TIMELINE,
    payload: null,
});

export const setTimelinesLoading = (loading: boolean) =>
    action(TimelinesActionTypes.SET_TIMELINES_LOADING);

export const createTimelineSetInput = (input: ICreateTimelineInput) =>
    action(TimelinesActionTypes.CREATE_TIMELINE_SET_INPUT, input);
export const createTimelineRequest = (input: ICreateTimelineInput) =>
    action(TimelinesActionTypes.CREATE_TIMELINE_REQUEST, input);
export const createTimelineSetError = (errors: string) =>
    action(TimelinesActionTypes.CREATE_TIMELINE_SET_ERROR, {errors});
export const createTimelineSetResult = (result: ICreateTimelineResult) =>
    action(TimelinesActionTypes.CREATE_TIMELINE_SET_RESULT, result);

export const getTimelinesRequest = () =>
    action(TimelinesActionTypes.GET_TIMELINES_REQUEST);
export const getTimelinesSetResult = (result: ITimeline) =>
    action(TimelinesActionTypes.GET_TIMELINES_SET_RESULT, result);
export const getTimelinesSetError = (error: string) =>
    action(TimelinesActionTypes.GET_TIMELINES_SET_ERROR, error);

export const getTimelineRequest = (timelineID: string, setUpdateTimelineInput: boolean) =>
    action(TimelinesActionTypes.GET_TIMELINE_REQUEST, {timelineID, setUpdateTimelineInput});
export const getTimelineRequestByShortcode = (timelineShortcode: string, setUpdateTimelineInput: boolean) =>
    action(TimelinesActionTypes.GET_TIMELINE_REQUEST_BY_SHORTCODE, {timelineShortcode, setUpdateTimelineInput});
export const getTimelineSetResult = (result: ITimeline) =>
    action(TimelinesActionTypes.GET_TIMELINE_SET_RESULT, result);

export const updateTimelineNameRequest = (input: IUpdateTimelineNameInput) =>
    action(TimelinesActionTypes.UPDATE_TIMELINE_NAME_REQUEST, input);

export const addOrRemoveTimelineMemberRequest = (input: IAddOrRemoveTimelineMemberInput) =>
    action(TimelinesActionTypes.ADD_OR_REMOVE_TIMELINE_MEMBER_REQUEST, input);

export const updateTimelineSortOrderRequest = (input: ITimelineUpdateSortOrderInput) =>
    action(TimelinesActionTypes.UPDATE_TIMELINE_SORT_ORDER_REQUEST, input);

export const updateTimelineCurrentSprintRequest = (input: ITimelineUpdateCurrentSprintInput) =>
    action(TimelinesActionTypes.UPDATE_TIMELINE_CURRENTSPRINT_REQUEST, input);

export const updateTimelineCurrentSprintSetResult = (input: ITimelineCommonResult) =>
    action(TimelinesActionTypes.UPDATE_TIMELINE_CURRENTSPRINT_SET_RESULT, input);

export const setTimelinesOrder = (timelinesOrder: string[]) =>
    action(TimelinesActionTypes.SET_TIMELINES_ORDER, timelinesOrder);

export const updateTimelineColorRequest = (input: ITimelineUpdateColorInput) =>
    action(TimelinesActionTypes.UPDATE_TIMELINE_COLOR_REQUEST, input);

export const updateTimelineUnitPointsRangeRequest = (input: ITimelineUpdateUnitPointsRangeInput) =>
    action(TimelinesActionTypes.UPDATE_TIMELINE_UNITPOINTSRANGE_REQUEST, input);

export const deleteTimelineRequest = (input: ITimelineDeleteInput) =>
    action(TimelinesActionTypes.DELETE_TIMELINE_REQUEST, input);

export const insertOrUpdateTimeline = (timeline: ITimeline) =>
    action(TimelinesActionTypes.INSERT_OR_UPDATE_TIMELINE, timeline);

export const updateTimelineSetInput = (input: ITimeline) =>
    action(TimelinesActionTypes.UPDATE_TIMELINE_SET_INPUT, input);

export const setCurrentTimelineTasksOrder = (input: string[]) =>
    action(TimelinesActionTypes.SET_CURRENT_TIMELINE_TASKS_ORDER, input);

export const addOrRemoveTimelineTasksRequest = (input: IAddOrRemoveTimelineTasksInput) =>
    action(TimelinesActionTypes.ADD_OR_REMOVE_TIMELINE_TASKS_REQUEST, input);

export const updateTimelineTaskRequest = (input: IUpdateTimelineTaskInput) =>
    action(TimelinesActionTypes.UPDATE_TIMELINE_TASK_REQUEST, input);

export const updateSortByTaskCode = (input?: boolean) =>
    action(TimelinesActionTypes.UPDATE_SORT_BY_TASK_CODE, input);

export const updateSortByPriority = (input?: boolean) =>
    action(TimelinesActionTypes.UPDATE_SORT_BY_PRIORITY, input);

export const updateSortByColumn = (input?: boolean) =>
    action(TimelinesActionTypes.UPDATE_SORT_BY_COLUMN, input);

export const updateGroupBy = (input?: TimelineGroupBy) =>
    action(TimelinesActionTypes.UPDATE_GROUP_BY, input);
