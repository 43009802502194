/*!
 * Copyright 2019 CTC. All rights reserved.
 *
 * Licensed under the terms of the LICENSE file distributed with this timeline.
 */

import "@atlaskit/css-reset";
import {
    Button,
    Callout,
    Classes,
    Dialog,
    Intent,
    Position,
    Slider,
    Spinner,
    Switch,
    Tooltip,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Select } from "@blueprintjs/select";
import _ from "lodash";
import React, { FormEvent } from "react";
import { Cookies } from "react-cookie";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { IApplicationState } from "../../store";
import { IStandardColor } from "../../store/colors/types";
import * as dialogsActions from "../../store/dialogs/actions";
import * as timelinesActions from "../../store/timelines/actions";
import { ICreateTimelineInput, ICreateTimelineResult } from "../../store/timelines/types";
import TimelineDetailPanel from "./timeline-detail-panel";

interface IPropsFromState {
    createTimelineLoading: boolean;
    createTimelineInput: ICreateTimelineInput;
    createTimelineResult: ICreateTimelineResult;
    defaultTimelineColor: IStandardColor;
}

interface IPropsFromDispatch {
    openCreateTimelineDialog: typeof dialogsActions.openCreateTimelineDialog;
    createTimelineRequest: typeof timelinesActions.createTimelineRequest;
    createTimelineSetResult: typeof timelinesActions.createTimelineSetResult;
    createTimelineSetInput: typeof timelinesActions.createTimelineSetInput;
}

interface IOwnProps {
    isOpen: boolean;
}

// Combine both state + dispatch props - as well as any props we want to pass - in a union type.
type AllProps = IPropsFromState & IPropsFromDispatch & IOwnProps;

class TimelineDetailDialog extends React.PureComponent<AllProps> {
    constructor(props) {
        super(props);
    }

    public componentDidMount() {
        // To do
    }

    public render() {
        // const {role} = this.state;
        return (
            <Dialog
                icon={IconNames.TIMELINE_EVENTS}
                onClose={this.handleCloseDialog}
                title="Create new timeline"
                isOpen={this.props.isOpen}
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                isCloseButtonShown={true}
                style={{
                    width: "80%",
                    maxWidth: "800px",
                }}
            >
                <div className={Classes.DIALOG_BODY}>
                    <TimelineDetailPanel />
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip
                            position={Position.BOTTOM_RIGHT}
                            content="Close"
                        >
                            <Button
                                onClick={this.handleCloseDialog}
                                // disabled={this.props.isLoginLoading}
                            >
                                Cancel
                            </Button>
                        </Tooltip>
                        {this.props.createTimelineResult.id === undefined ?
                            (
                                <Tooltip
                                    position={Position.BOTTOM_RIGHT}
                                    content="Create new timeline"
                                >
                                    <Button
                                        intent={Intent.PRIMARY}
                                        onClick={this.handleCreateTimeline}
                                        loading={this.props.createTimelineLoading}
                                        // disabled={this.props.isLoginLoading}
                                    >
                                        Create
                                    </Button>
                                </Tooltip>
                            ) :
                            (
                                <Tooltip
                                    position={Position.BOTTOM_RIGHT}
                                    content="Create more"
                                >
                                    <Button
                                        intent={Intent.PRIMARY}
                                        onClick={this.handleCreateMoreTimeline}
                                        loading={this.props.createTimelineLoading}
                                        // disabled={this.props.isLoginLoading}
                                    >
                                        Create more
                                    </Button>
                                </Tooltip>
                            )
                        }
                    </div>
                </div>
            </Dialog>
        );
    }

    private handleCloseDialog = () => {
        this.props.openCreateTimelineDialog(false);
        this.handleCreateMoreTimeline();
    }

    private handleCreateTimeline = () => {
        if (_.isEmpty(this.props.createTimelineInput.name)) {
            this.props.createTimelineSetResult({
                id: undefined,
                errors: "The name of the timeline can not be empty",
            });
            return;
        }
        this.props.createTimelineRequest(this.props.createTimelineInput);
    }

    private handleCreateMoreTimeline = () => {
        this.props.createTimelineSetResult({
            errors: undefined,
            id: undefined,
        });

        this.props.createTimelineSetInput({
            members: [],
            name: "",
            color: this.props.defaultTimelineColor,
        });
    }

    private validateInputs = () => {
        const isFormValid = true;

        return isFormValid;
    }
}

// It's usually good practice to only include one context at a time in a connected component.
// Although if necessary, you can always include multiple contexts. Just make sure to
// separate them from each other to prevent prop conflicts.
const mapStateToProps = ({ timelines }: IApplicationState) => ({
    createTimelineLoading: timelines.createTimelineLoading,
    createTimelineInput: timelines.createTimelineInput,
    createTimelineResult: timelines.createTimelineResult,
    defaultTimelineColor: timelines.defaultTimelineColor,
});

// Mapping our action dispatcher to props is especially useful when creating container components.
const mapDispatchToProps = (dispatch: Dispatch) => ({
    openCreateTimelineDialog: (isOpen: boolean) =>
        dispatch(dialogsActions.openCreateTimelineDialog(isOpen)),
    createTimelineSetResult: (result: ICreateTimelineResult) =>
        dispatch(timelinesActions.createTimelineSetResult(result)),
    createTimelineSetInput: (input: ICreateTimelineInput) =>
        dispatch(timelinesActions.createTimelineSetInput(input)),
    createTimelineRequest: (input: ICreateTimelineInput) =>
        dispatch(timelinesActions.createTimelineRequest(input)),
});

// Now let's connect our component!
// With redux v4's improved typings, we can finally omit generics here.
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TimelineDetailDialog);
