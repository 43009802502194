import React from 'react';
import createBlockStyleButton from '../../utils/createBlockStyleButton';
import { Icon } from '@blueprintjs/core';

export default createBlockStyleButton({
  blockType: 'unordered-list-item',
  children: (
    // <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
    //   <path d="M4 10.5c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5zm0-6c-.83 0-1.5.67-1.5 1.5S3.17 7.5 4 7.5 5.5 6.83 5.5 6 4.83 4.5 4 4.5zm0 12c-.83 0-1.5.68-1.5 1.5s.68 1.5 1.5 1.5 1.5-.68 1.5-1.5-.67-1.5-1.5-1.5zM7 19h14v-2H7v2zm0-6h14v-2H7v2zm0-8v2h14V5H7z" />
    //   <path d="M0 0h24v24H0V0z" fill="none" />
    // </svg>
    <Icon icon="properties" iconSize={20} />
  ),
});
