/*!
 * Copyright 2019 CTC. All rights reserved.
 *
 * Licensed under the terms of the LICENSE file distributed with this project.
 */

import {
    Alignment,
    Button,
    Card,
    Checkbox,
    Classes,
    ContextMenu,
    Dialog,
    EditableText,
    Elevation,
    FormGroup,
    Icon,
    InputGroup,
    Intent,
    Menu,
    MenuDivider,
    MenuItem,
    Navbar,
    NavbarDivider,
    NavbarGroup,
    NumericInput,
    Popover,
    Position,
    Tag,
    Tooltip,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { History } from "history";
import _ from "lodash";
import React, { FormEvent } from "react";
import { Draggable, DraggableStateSnapshot } from "react-beautiful-dnd";
import { connect } from "react-redux";
import { Route, Router } from "react-router";
import { withRouter } from "react-router-dom";
import { Dispatch } from "redux";
import styled from "styled-components";
import { IApplicationState } from "../../store";
import { IStandardColor, IStandardColorGroup } from "../../store/colors/types";
import { IColumn } from "../../store/columns/types";
import * as dialogsActions from "../../store/dialogs/actions";
import { ILabel } from "../../store/labels/types";
import { IPriority } from "../../store/priorities/types";
import { IProject } from "../../store/projects/types";
import { ISprint } from "../../store/sprints/types";
import * as tasksActions from "../../store/tasks/actions";
import * as usersActions from "../../store/users/actions";
import { IUser } from "../../store/users/types";
import invert, { HexColor, IBlackWhite, IRGB, RgbArray } from "../../utils/invertcolor";
import { IStringTMap } from "../../utils/types";
import ColorsContextMenu from "../colors/colors-context-menu";
import UserImage from "../userimage";

const Container = styled.div`
    margin-bottom: 8px;
    display: flex;
    flex-direction: row;
`;

interface ILabelItemTitleFieldState {
    title: string;
    color: IStandardColor;
}

interface IPropsFromState {

}

interface IPropsFromDispatch {

}

interface IOwnProps {
    initialTitle?: string;
    initialColor?: IStandardColor;
    large?: boolean;
    checked?: boolean;
    alignIndicator?: Alignment;
    onTitleChange?: (newValue: string) => void;
    onColorChange?: (newValue: IStandardColor) => void;
    onComplete?: (completed: boolean) => void;
    onBlur?: () => void;
    onFocus?: () => void;
    userChecked?: IUser;
    colorgroups: IStandardColorGroup[];
}

// Combine both state + dispatch props - as well as any props we want to pass - in a union type.
type AllProps = IPropsFromState & IPropsFromDispatch & IOwnProps;

class LabelItemTitleField extends React.PureComponent<AllProps, ILabelItemTitleFieldState> {
    public state: ILabelItemTitleFieldState = {
        title: this.props.initialTitle !== undefined ? this.props.initialTitle  : "",
        color: this.props.initialColor !== undefined ?
            this.props.initialColor :
            {alpha: 0.0, red: 255, green: 255, blue: 255},
    };

    private debounceTitleChange = _.debounce(() => {
        if (this.props.onTitleChange !== undefined) {
            this.props.onTitleChange(
                this.state.title,
            );
        }
    }, 500);

    public render() {
        return (
            <div
                style={{
                    flexGrow: 1,
                }}
            >
                <InputGroup
                    style={{
                        // backgroundColor: `rgba(
                        //     ${label.backgroundColor.red},
                        //     ${label.backgroundColor.green},
                        //     ${label.backgroundColor.blue},
                        //     0.2)`,
                        color: `${
                            invert(
                            {
                                r: this.state.color.red,
                                g: this.state.color.green,
                                b: this.state.color.blue,
                            })
                        }`,
                        backgroundColor: `rgba(
                            ${this.state.color.red},
                            ${this.state.color.green},
                            ${this.state.color.blue},
                            ${this.state.color.alpha})`,
                    }}
                    value={this.state.title}
                    placeholder={"Type your task type..."}
                    onBlur={() => {
                        if (this.props.onBlur !== undefined) {
                            this.props.onBlur();
                        }
                    }}
                    onFocus={() => {
                        if (this.props.onFocus !== undefined) {
                            this.props.onFocus();
                        }
                    }}
                    rightElement={
                        <Button
                            onClick={(e) => this.showExistingLabelColorContextMenu(e)}
                            active={true}
                            // minimal={true}
                            icon={
                                <Icon
                                    icon={IconNames.TINT}
                                    color={
                                        `rgba(
                                            ${this.state.color.red},
                                            ${this.state.color.green},
                                            ${this.state.color.blue},
                                            1.0)`
                                    }
                                />
                            }
                            // loading={_.isUndefined(this.props.userChecked.avatarBase64)}
                            style={{
                                maxWidth: `24px`,
                                maxHeight: `24px`,
                                borderRadius: "2px",
                                overflow: "hidden",
                                padding: "0px",
                                marginRight: "5px",
                                justifyContent: "center",
                                display: "flex",
                                flexDirection: "column",
                            }}
                        />
                    }
                    onChange={(e) => {
                        this.onTitleChange(e.target.value);
                    }}
                />
            </div>
        );
    }

    private onTitleChange = (newValue: string) => {
        this.setState({
            title: newValue,
        });
        this.debounceTitleChange();
    }

    // private onCompletedChange = (e: FormEvent<HTMLInputElement>) => {
    //     if (this.props.onComplete !== undefined) {
    //         this.props.onComplete((e.target as HTMLInputElement).checked);
    //     }
    // }

    private handleCompleteLabel = (e) => {
        if (this.props.onComplete !== undefined) {
            this.props.onComplete(true);
        }
    }

    private handleUncompleteLabel = (e) => {
        if (this.props.onComplete !== undefined) {
            this.props.onComplete(false);
        }
    }

    private showExistingLabelColorContextMenu = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        // must prevent default to cancel parent's context menu
        e.preventDefault();
        // invoke static API, getting coordinates from mouse event
        ContextMenu.show(
            <ColorsContextMenu
                colorgroups={this.props.colorgroups}
                onSelectColor={(selectedColor: IStandardColor) => {
                    this.setState({
                        color: selectedColor,
                    });
                    if (this.props.onColorChange !== undefined) {
                        this.props.onColorChange(
                            selectedColor,
                        );
                    }
                }}
            />,
            { left: e.clientX, top: e.clientY },
            () => {return; },
        );
    }
}

// It's usually good practice to only include one context at a time in a connected component.
// Although if necessary, you can always include multiple contexts. Just make sure to
// separate them from each other to prevent prop conflicts.
const mapStateToProps = ({ }: IApplicationState) => ({

});

// Mapping our action dispatcher to props is especially useful when creating container components.
const mapDispatchToProps = (dispatch: Dispatch) => ({

});

// Now let's connect our component!
// With redux v4's improved typings, we can finally omit generics here.
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(LabelItemTitleField);
