/*!
 * Copyright 2019 CTC. All rights reserved.
 *
 * Licensed under the terms of the LICENSE file distributed with this project.
 */

import { IPanelProps } from "@blueprintjs/core";
import { action } from "typesafe-actions";
import { IProjectUpdateSortOrderInput } from "../projects/types";
import {
    IAddLabelState,
    IAddOrRemoveLabelManagerInput,
    IEditLabelState,
    ILabelCommonResult,
    ILabelCreateInput,
    ILabelDeleteInput,
    ILabelUpdateColorInput,
    ILabelUpdateInput,
    ILabelUpdateSortOrderInput,
    ILabelUpdateTitleInput,
    LabelsActionTypes,
} from "./types";

// export const setAddLabelState = (input: IAddLabelState) =>
//     action(LabelsActionTypes.SET_ADD_LABEL_STATE, input);

export const setEditLabelState = (input: IEditLabelState) =>
    action(LabelsActionTypes.SET_EDIT_LABEL_STATE, input);

export const createLabelSetInput = (input: ILabelCreateInput) =>
    action(LabelsActionTypes.CREATE_LABEL_SET_INPUT, input);
export const createLabelRequest = (input: ILabelCreateInput) =>
    action(LabelsActionTypes.CREATE_LABEL_REQUEST, input);
export const createLabelSetError = (errors: string) =>
    action(LabelsActionTypes.CREATE_LABEL_SET_ERROR, {errors});
export const createLabelSetResult = (result: ILabelCommonResult) =>
    action(LabelsActionTypes.CREATE_LABEL_SET_RESULT, result);

export const getLabelRequest = (labelID: string) =>
    action(LabelsActionTypes.GET_LABEL_REQUEST, labelID);

export const updateLabelSetInput = (input: ILabelUpdateInput) =>
    action(LabelsActionTypes.UPDATE_LABEL_SET_INPUT, input);
export const updateLabelRequest = (input: ILabelUpdateInput) =>
    action(LabelsActionTypes.UPDATE_LABEL_REQUEST, input);
export const updateLabelSetError = (errors: string) =>
    action(LabelsActionTypes.UPDATE_LABEL_SET_ERROR, {errors});
export const updateLabelSetResult = (result: ILabelCommonResult) =>
    action(LabelsActionTypes.UPDATE_LABEL_SET_RESULT, result);

export const updateLabelTitleRequest = (input: ILabelUpdateTitleInput) =>
    action(LabelsActionTypes.UPDATE_LABEL_TITLE_REQUEST, input);

export const updateLabelColorRequest = (input: ILabelUpdateColorInput) =>
    action(LabelsActionTypes.UPDATE_LABEL_COLOR_REQUEST, input);

export const addOrRemoveLabelManagerRequest = (input: IAddOrRemoveLabelManagerInput) =>
    action(LabelsActionTypes.ADD_OR_REMOVE_LABEL_MANAGER_REQUEST, input);

export const updateLabelSortOrderRequest = (input: ILabelUpdateSortOrderInput) =>
    action(LabelsActionTypes.UPDATE_LABEL_SORT_ORDER_REQUEST, input);

export const deleteLabelRequest = (input: ILabelDeleteInput) =>
    action(LabelsActionTypes.DELETE_LABEL_REQUEST, input);
