/*!
 * Copyright 2019 CTC. All rights reserved.
 *
 * Licensed under the terms of the LICENSE file distributed with this timeline.
 */

import { Button, Classes, Spinner } from "@blueprintjs/core";
import _ from "lodash";
import React from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import { Route } from "react-router";
import { Dispatch } from "redux";
import styled from "styled-components";
import timelineTestData from "../../data/timeline-test-data";
import { IApplicationState } from "../../store";
import * as dialogsActions from "../../store/dialogs/actions";
import * as timelinesActions from "../../store/timelines/actions";
import { ITimeline, ITimelineUpdateSortOrderInput } from "../../store/timelines/types";
import { generateMidString } from "../../utils/strings";
import { IStringTMap } from "../../utils/types";
import TimelineListItem from "./timelinelistitem";

const TimelineList = styled.div`
    ${"" /* padding: 8px;*/}
    transition: background-color 0.2s ease;
    ${"" /* background-color: ${props => (props.isDraggingOver ? 'lightgrey' : 'inherit')}; */}
    ${"" /* min-height: 100px; */}
`;

const AddCardContainer = styled.div`
    ${"" /* padding: 8px;*/}
    margin-bottom: 8px;
    margin-top: 0px;
`;

interface ITimelineListTabPanelState {
    selectedTabId: string | number;
}

interface IPropsFromState {
    getTimelinesLoading: boolean;
    timelineMap: IStringTMap<ITimeline>;
    timelinesOrder: string[];
}

interface IPropsFromDispatch {
    openCreateTimelineDialog: typeof dialogsActions.openCreateTimelineDialog;
    updateTimelineSortOrderRequest: typeof timelinesActions.updateTimelineSortOrderRequest;
    setTimelinesOrder: typeof timelinesActions.setTimelinesOrder;
}

interface IOwnProps {
    goToTimelineBoard: (props: any) => void;
    allowAddNewTimeline: boolean;
}

// Combine both state + dispatch props - as well as any props we want to pass - in a union type.
type AllProps = IPropsFromState & IPropsFromDispatch & IOwnProps;

interface ITimelineListPanelState {
    timelines: any[any];
    timelineOrder: string[];
}

class TimelineListPanel extends React.PureComponent<AllProps, ITimelineListPanelState> {
    public state: ITimelineListPanelState = {
        timelines: {
            "timeline-1": {id: "timeline-1", title: "Cooking the Books"},
            "timeline-2": {id: "timeline-2", title: "Drinking the Profit"},
            "timeline-3": {id: "timeline-3", title: "Keeping it cool"},
        },
        // Facilitate reordering of the timelines
        timelineOrder: ["timeline-1", "timeline-2", "timeline-3"],
    };

    public onDragStart = (result: any) => {
        // To do
    }

    public onDragUpdate = (update: any) => {
        // To do
    }

    public onDragEnd = (result: any) => {
        // document.body.style.color = 'inherit';
        // document.body.style.backgroundColor = 'inherit';
        const { destination, source, draggableId, type } = result;
        // Check if there is destination
        if (!destination) {
            return;
        }
        // Check if the location is the same
        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }

        if (type === "timeline") {
            const newTimelinesOrder = Array.from(this.props.timelinesOrder);
            // Removing the old timeline id
            newTimelinesOrder.splice(source.index, 1);
            // Add the new timeline id
            newTimelinesOrder.splice(destination.index, 0, draggableId);

            // const newState = {
            //     ...this.state,
            //     timelineOrder: newTimelinesOrder,
            // };

            // this.setState(newState);
            const currentNewIndex: number = newTimelinesOrder.indexOf(draggableId);
            const afterTimelineID: string | undefined = newTimelinesOrder[currentNewIndex - 1];
            const beforeTimelineID: string | undefined = newTimelinesOrder[currentNewIndex + 1];
            // let prevTimelineLocation: string = "";
            // let nextTimelineLocation: string = "";
            // if (!_.isUndefined(prevTimelineID)) {
            //     const prevTimeline = this.props.timelineMap[prevTimelineID];
            //     if (!_.isUndefined(prevTimeline)) {
            //         prevTimelineLocation = prevTimeline.location;
            //     }
            // }
            // if (!_.isUndefined(nextTimelineID)) {
            //     const nextTimeline = this.props.timelineMap[nextTimelineID];
            //     if (!_.isUndefined(nextTimeline)) {
            //         nextTimelineLocation = nextTimeline.location;
            //     }
            // }
            // const newLocation: string = generateMidString(prevTimelineLocation, nextTimelineLocation);
            // console.log("New location = ", newLocation,
            //     "prevTimelineLocation = ", prevTimelineLocation,
            //     "nextTimelineLocation = ", nextTimelineLocation);

            // Set new timelines order
            this.props.setTimelinesOrder(newTimelinesOrder);
            // Request server to update as well
            this.props.updateTimelineSortOrderRequest({
                id: draggableId,
                beforeTimelineID,
                afterTimelineID,
            });

            return;
        }
    }

    public render() {
        // return <Button onClick={this.openSettingsPanel} text="Settings" />
        return (
            <DragDropContext
                onDragStart={this.onDragStart}
                onDragUpdate={this.onDragUpdate}
                onDragEnd={this.onDragEnd}
            >
                <Droppable
                    droppableId="all-timelines"
                    direction="vertical"
                    type="timeline"
                >
                    {(provided) => (
                        <TimelineList
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {/* {this.state.timelineOrder.map((timelineId, index) => {
                                const timeline = this.state.timelines[timelineId];
                                return (
                                    <TimelineListItem
                                        key={timeline.id}
                                        timeline={timeline}
                                        timelineMap={this.state.timelines}
                                        index={index}
                                    />
                                );
                            })} */}
                            {
                                _.map(this.props.timelinesOrder, (timelineID: string, index: number) => {
                                    const eachTimeline = this.props.timelineMap[timelineID];
                                    // const index = _.indexOf(_.keys(this.props.timelineMap), timelineID);
                                    return (
                                        <TimelineListItem
                                            key={eachTimeline.id}
                                            timeline={eachTimeline}
                                            // timelineMap={this.props.timelineMap}
                                            index={index}
                                        />
                                    );
                                })
                            }
                        </TimelineList>
                    )}
                </Droppable>
                {
                    this.props.allowAddNewTimeline ?
                    (
                        <AddCardContainer>
                            {this.props.getTimelinesLoading ?
                                (
                                    <Spinner
                                        size={50}
                                    />
                                ) :
                                (
                                    <Route
                                        render={({history}) => (
                                            <Button
                                                onClick={this.handleAddNewTimeline}
                                                text="Add new timeline"
                                                icon="add"
                                                className={Classes.NAVBAR}
                                            />
                                        )}
                                    />
                                )
                            }
                        </AddCardContainer>
                    )
                    :
                    (
                        <div/>
                    )
                }
            </DragDropContext>
        );
    }

    private handleAddNewTimeline = () => {
        this.props.openCreateTimelineDialog(true);
        // console.log("generateMidString aaaaa-bbbbb", generateMidString("aaaaa", "bbbbb"));
        // console.log("generateMidString aaaaa-aaaab", generateMidString("aaaaa", "aaaab"));
        // console.log("generateMidString aaaam-aaaan", generateMidString("aaaam", "aaaan"));
        // console.log("generateMidString aaaaa-aaaac", generateMidString("aaaaa", "aaaac"));
        // console.log("generateMidString aaaaa-aaaaa", generateMidString("aaaaa", "aaaaa"));
        // console.log("generateMidString -aaaaa", generateMidString("", "aaaaa"));
        // console.log("generateMidString aaaaa-", generateMidString("aaaaa", ""));
        // console.log("generateMidString -", generateMidString("", ""));
        // console.log("generateMidString -aaa", generateMidString("", "aaa"));
    }
}

// It's usually good practice to only include one context at a time in a connected component.
// Although if necessary, you can always include multiple contexts. Just make sure to
// separate them from each other to prevent prop conflicts.
const mapStateToProps = ({ navbar, timelines }: IApplicationState) => ({
    boardTitle: navbar.boardTitle,
    getTimelinesLoading: timelines.getTimelinesLoading,
    timelineMap: timelines.timelineMap,
    timelinesOrder: timelines.timelinesOrder,
});

// Mapping our action dispatcher to props is especially useful when creating container components.
const mapDispatchToProps = (dispatch: Dispatch) => ({
    openCreateTimelineDialog: (isOpen: boolean) =>
        dispatch(dialogsActions.openCreateTimelineDialog(isOpen)),
    updateTimelineSortOrderRequest: (input: ITimelineUpdateSortOrderInput) =>
        dispatch(timelinesActions.updateTimelineSortOrderRequest(input)),
    setTimelinesOrder: (timelinesOrder: string[]) =>
        dispatch(timelinesActions.setTimelinesOrder(timelinesOrder)),
});

// Now let's connect our component!
// With redux v4's improved typings, we can finally omit generics here.
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TimelineListPanel);
