exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".editor-toolbar-style_toolbar__1W4wq {\n    /* left: 50%;\n    transform: translate(-50%) scale(0);\n    position: absolute;\n    border: 1px solid #ddd;\n    background: #fff;\n    border-radius: 2px;\n    box-shadow: 0px 1px 3px 0px rgba(220,220,220,1);\n    z-index: 2;\n    box-sizing: border-box; */\n    left: 50%;\n    -webkit-transform: translate(-50%) scale(0);\n            transform: translate(-50%) scale(0);\n    position: absolute;\n    border: 1px solid #111;\n    background: #000;\n    border-radius: 4px;\n    /* box-shadow: 0px 1px 3px 0px rgba(220,220,220,1); */\n    z-index: 2;\n    box-sizing: border-box;\n}\n  \n.editor-toolbar-style_toolbar__1W4wq:after, .editor-toolbar-style_toolbar__1W4wq:before {\n    top: 100%;\n    left: 50%;\n    border: solid transparent;\n    content: \" \";\n    height: 0;\n    width: 0;\n    position: absolute;\n    pointer-events: none;\n}\n  \n.editor-toolbar-style_toolbar__1W4wq:after {\n    /* border-color: rgba(255, 255, 255, 0);\n    border-top-color: #fff;\n    border-width: 4px;\n    margin-left: -4px; */\n    border-color: rgba(221, 221, 221, 0);\n    border-top-color: #111;\n    border-width: 6px;\n    margin-left: -6px;\n}\n\n.editor-toolbar-style_toolbar__1W4wq:before {\n    /* border-color: rgba(221, 221, 221, 0);\n    border-top-color: #ddd;\n    border-width: 6px;\n    margin-left: -6px; */\n    border-color: rgba(255, 255, 255, 0);\n    border-top-color: #333;\n    border-width: 4px;\n    margin-left: -4px;\n}", ""]);

// exports
exports.locals = {
	"toolbar": "editor-toolbar-style_toolbar__1W4wq"
};