/*!
 * Copyright 2019 CTC. All rights reserved.
 *
 * Licensed under the terms of the LICENSE file distributed with this project.
 */

import _ from "lodash";
import { Reducer } from "redux";
import { updateLabelColorRequest } from "./actions";
import {ILabelsState, LabelsActionTypes, LabelType} from "./types";

const initialState: ILabelsState = {
    addLabelState: {
        label: {
            id: "",
            taskIDs: [],
            title: "",
            managers: [],
            color: {
                red: 255,
                green: 255,
                blue: 255,
                alpha: 1.0,
            },
        },
    },
    editLabelState: {
        label: {
            id: "",
            taskIDs: [],
            title: "",
            managers: [],
            color: {
                red: 255,
                green: 255,
                blue: 255,
                alpha: 1.0,
            },
        },
    },
    createLabelInput: {
        title: "",
        color: {
            red: 255,
            green: 255,
            blue: 255,
            alpha: 1.0,
        },
        type: LabelType.TASK_TYPE,
    },
    createLabelLoading: false,
    createLabelResult: {
        id: undefined,
        errors: undefined,
    },
    updateLabelLoading: false,
    updateLabelInput: {
        id: "",
        title: "",
        managers: [],
        projectID: "",
        taskIDs: [],
        color: {
            red: 255,
            green: 255,
            blue: 255,
            alpha: 1.0,
        },
    },
    updateLabelResult: {
        id: undefined,
        errors: undefined,
    },
};

const reducer: Reducer<ILabelsState> = (state = initialState, action) => {
    switch (action.type) {
        case LabelsActionTypes.SET_ADD_LABEL_STATE:
            return {
                ...state,
                addLabelState: action.payload,
            };
        case LabelsActionTypes.SET_EDIT_LABEL_STATE:
            return {
                ...state,
                editLabelState: action.payload,
            };
        case LabelsActionTypes.CREATE_LABEL_SET_INPUT:
            return {
                ...state,
                createLabelInput: action.payload,
            };
        case LabelsActionTypes.CREATE_LABEL_REQUEST:
            return {
                ...state,
                createLabelLoading: true,
            };
        case LabelsActionTypes.CREATE_LABEL_SET_ERROR:
            return {
                ...state,
                createLabelLoading: false,
                createLabelResult: action.payload,
            };
        case LabelsActionTypes.CREATE_LABEL_SET_RESULT:
            return {
                ...state,
                createLabelLoading: false,
                createLabelResult: action.payload,
            };
        case LabelsActionTypes.UPDATE_LABEL_SET_INPUT:
            return {
                ...state,
                updateLabelInput: action.payload,
            };
        case LabelsActionTypes.UPDATE_LABEL_REQUEST:
            return {
                ...state,
                updateLabelLoading: true,
            };
        case LabelsActionTypes.UPDATE_LABEL_SET_ERROR:
            return {
                ...state,
                updateLabelLoading: false,
                updateLabelResult: action.payload,
            };
        case LabelsActionTypes.UPDATE_LABEL_SET_RESULT:
            return {
                ...state,
                updateLabelLoading: false,
                updateLabelResult: action.payload,
            };
        case LabelsActionTypes.GET_LABEL_REQUEST:
        case LabelsActionTypes.UPDATE_LABEL_TITLE_REQUEST:
        case LabelsActionTypes.UPDATE_LABEL_COLOR_REQUEST: {
            const actionData: ReturnType<typeof updateLabelColorRequest> = {
                payload : action.payload,
                type: LabelsActionTypes.UPDATE_LABEL_COLOR_REQUEST,
            };

            return {
                ...state,
            };
        }
        case LabelsActionTypes.UPDATE_LABEL_SORT_ORDER_REQUEST:
        case LabelsActionTypes.DELETE_LABEL_REQUEST:
        default:
            return state;
    }
};

export { reducer as labelsReducer };
