/*!
 * Copyright 2019 CTC. All rights reserved.
 *
 * Licensed under the terms of the LICENSE file distributed with this timeline.
 */

import {
    Alignment,
    Button,
    Classes,
    ContextMenu,
    Icon,
    Intent,
    Menu,
    MenuDivider,
    MenuItem,
    Position,
    Switch,
    Tooltip,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import PropTypes from "prop-types";
import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import styled from "styled-components";
import { IApplicationState } from "../../store";
import { IStandardColor, IStandardColorGroup } from "../../store/colors/types";
import * as loginsActions from "../../store/logins/actions";
import * as navbarActions from "../../store/navbar/actions";
import * as timelinesActions from "../../store/timelines/actions";
import {
    ITimeline,
    ITimelineDeleteInput,
    ITimelineUpdateColorInput,
    ITimelineUpdateUnitPointsRangeInput,
} from "../../store/timelines/types";
import { CONST_MAXIMUM_UNIT_POINTS_POSSIBLE } from "../../utils/constants";
import UnitItemPointsField from "../board/unit-item-points-field";
import ColorsContextMenu from "../colors/colors-context-menu";

interface ITimelineItemContextMenuState {
    isConfirmingDelete: boolean;
}

// Props passed from mapStateToProps
interface IPropsFromState {

}

// Props passed from mapDispatchToProps
interface IPropsFromDispatch {
    updateTimelineColorRequest: typeof timelinesActions.updateTimelineColorRequest;
    updateTimelineUnitPointsRangeRequest: typeof timelinesActions.updateTimelineUnitPointsRangeRequest;
    deleteTimelineRequest: typeof timelinesActions.deleteTimelineRequest;
}

// Component-specific props.
interface IOwnProps {
    timeline: ITimeline;
    colorgroups: IStandardColorGroup[];
}

// Combine both state + dispatch props - as well as any props we want to pass - in a union type.
type AllProps = IPropsFromState & IPropsFromDispatch & IOwnProps;

class TimelineItemContextMenu extends React.PureComponent<AllProps, ITimelineItemContextMenuState> {
    public state: ITimelineItemContextMenuState = {
        isConfirmingDelete: false,
    };
    public updateTimelineUnitPointsRangeRequest;
    public render() {
        return (
            <Menu
                className={Classes.ELEVATION_1}
            >
                <MenuDivider title="SETTINGS" />
                <MenuItem
                    icon="style"
                    text="Archive"
                    disabled={true}
                    shouldDismissPopover={false}
                    labelElement={
                        <Switch
                            large={true}
                            disabled={true}
                            // checked={this.props.isUsingDarkTheme}
                            alignIndicator={Alignment.RIGHT}
                            // onChange={this.onToggleDarkTheme}
                        />
                    }
                />
                <MenuItem
                    text="Color code"
                    shouldDismissPopover={true}
                    icon={
                        <Icon
                            icon={IconNames.TINT}
                            color={
                                `rgba(
                                    ${this.props.timeline.color.red},
                                    ${this.props.timeline.color.green},
                                    ${this.props.timeline.color.blue},
                                    ${this.props.timeline.color.alpha})`
                            }
                        />
                    }
                    labelElement={
                        <Button
                            text="Change"
                            onClick={(e) => this.showTimelineColorContextMenu(e)}
                        />
                    }
                >
                    {/* <ColorsContextMenu
                        colorgroups={this.props.colorgroups}
                        onSelectColor={(selectedColor: IStandardColor) => {
                            this.props.updateTimelineColorRequest({
                                id: this.props.timeline.id,
                                color: selectedColor,
                            });
                        }}
                    /> */}
                </MenuItem>
                <MenuItem
                    icon={IconNames.REMOVE}
                    text="Delete"
                    shouldDismissPopover={false}
                    onClick={this.handleConfirmDelete}
                />
            </Menu>
        );
    }

    // private handleRemoveTimeline = (e: React.MouseEvent<HTMLElement>) => {
    //     this.props.deleteTimelineRequest({
    //         id: this.props.timeline.id,
    //     });
    // }

    private handleDelete = () => {
        this.setState({
            isConfirmingDelete: true,
        });
    }

    private handleNotConfirmDelete = () => {
        this.setState({
            isConfirmingDelete: false,
        });
    }

    private handleConfirmDelete = () => {
        this.setState({
            isConfirmingDelete: false,
        });

        this.props.deleteTimelineRequest({
            id: this.props.timeline.id,
        });
    }

    private showTimelineColorContextMenu = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        // must prevent default to cancel parent's context menu
        e.preventDefault();
        // invoke static API, getting coordinates from mouse event
        ContextMenu.show(
            <ColorsContextMenu
                colorgroups={this.props.colorgroups}
                onSelectColor={(selectedColor: IStandardColor) => {
                    this.props.updateTimelineColorRequest({
                        id: this.props.timeline.id,
                        color: selectedColor,
                    });
                }}
            />,
            { left: e.clientX, top: e.clientY },
            () => {return; },
        );
    }
}

// It's usually good practice to only include one context at a time in a connected component.
// Although if necessary, you can always include multiple contexts. Just make sure to
// separate them from each other to prevent prop conflicts.
const mapStateToProps = ({ colors }: IApplicationState) => ({
    colorgroups: colors.colorgroups,
});

// Mapping our action dispatcher to props is especially useful when creating container components.
const mapDispatchToProps = (dispatch: Dispatch) => ({
    updateTimelineColorRequest: (input: ITimelineUpdateColorInput) =>
        dispatch(timelinesActions.updateTimelineColorRequest(input)),
    updateTimelineUnitPointsRangeRequest: (input: ITimelineUpdateUnitPointsRangeInput) =>
        dispatch(timelinesActions.updateTimelineUnitPointsRangeRequest(input)),
    deleteTimelineRequest: (input: ITimelineDeleteInput) =>
        dispatch(timelinesActions.deleteTimelineRequest(input)),
});

// Now let's connect our component!
// With redux v4's improved typings, we can finally omit generics here.
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TimelineItemContextMenu);
