/*!
 * Copyright 2019 CTC. All rights reserved.
 *
 * Licensed under the terms of the LICENSE file distributed with this project.
 */

import { delay } from "redux-saga";
import { all, call, fork, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { IApplicationState } from "..";
import { callApi, callApiWithAuthToken } from "../../utils/api";
import {
  getUsers,
  getUsersError,
  getUsersResult,
  updateUserRequest,
  updateUserSetError,
  updateUserSetResult,
} from "./actions";
import { IUserCommonResult, UsersActionTypes } from "./types";

const API_ENDPOINT: string = process.env.REACT_APP_TASK_RIPPLE_API!;
// const API_ENDPOINT = "https://api.opendota.com";

const getAuthToken = (state: IApplicationState) =>
  state.logins.result.authtoken ? state.logins.result.authtoken! : "";

const getCookies = (state: IApplicationState) =>
  state.cookies.cookies;

function* handleGetUsers(action: ReturnType<typeof getUsers>) {
  try {
    yield call(delay, 500); // Debouncing written by takeLatest
                            // Credit: https://gist.github.com/Calvin-Huang/698cbb954d714a41c1726d0cee1be629
    // To call async functions, use redux-saga's `call()`.
    const authToken = yield select(getAuthToken);
    const res = yield call(callApiWithAuthToken, "get", API_ENDPOINT, "/user", authToken);

    if (res.error) {
      yield put(getUsersError(res.error));
    } else {
      yield put(getUsersResult(res));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(getUsersError(err.stack!));
    } else {
      yield put(getUsersError("An unknown error occured."));
    }
  }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga
function* watchGetUsers() {
  yield takeLatest(UsersActionTypes.GET_USERS, handleGetUsers);
}

function* handleUpdateUserRequest(
  action: ReturnType<typeof updateUserRequest>,
) {
  try {
    yield call(delay, 1000);  // Debouncing written by takeLatest
                              // Credit: https://gist.github.com/Calvin-Huang/698cbb954d714a41c1726d0cee1be629
    // To call async functions, use redux-saga's `call()`.
    const authToken = yield select(getAuthToken);
    const res = yield call(callApiWithAuthToken,
      "post",
      API_ENDPOINT,
      "/user",
      authToken,
      action.payload);

    const resultData: IUserCommonResult = res;
    yield put(updateUserSetResult(resultData));
    // // Reload the projects
    // yield put(getProjectsRequest());

  } catch (err) {
    if (err instanceof Error) {
      yield put(updateUserSetError(err.stack!));
    } else {
      yield put(updateUserSetError("An unknown error occured."));
    }
  }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga
function* watchUpdateUserRequest() {
  yield takeEvery(
    UsersActionTypes.UPDATE_USER_REQUEST,
    handleUpdateUserRequest,
  );
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* usersSaga() {
  yield all([
    fork(watchGetUsers),
    fork(watchUpdateUserRequest),
  ]);
}

export default usersSaga;
