exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles_link__21RhE, .styles_link__21RhE:visited {\n  color: #5e93c5;\n  text-decoration: none;\n}\n\n.styles_link__21RhE:hover, .styles_link__21RhE:focus {\n  color: #7eadda;\n  outline: 0; /* reset for :focus */\n  cursor: pointer;\n}\n\n.styles_link__21RhE:active {\n  color: #4a7bab;\n}\n", ""]);

// exports
exports.locals = {
	"link": "styles_link__21RhE"
};