/*!
 * Copyright 2019 CTC. All rights reserved.
 *
 * Licensed under the terms of the LICENSE file distributed with this timeline.
 */

import { string } from "prop-types";
import { IStringTMap } from "../../utils/types";
import { IStandardColor } from "../colors/types";
import { IColumn } from "../columns/types";
import { IPriority } from "../priorities/types";
import { IProject } from "../projects/types";
import { ISprint } from "../sprints/types";
import { ITask } from "../tasks/types";

export const ADD_NEW_TIMELINE = "ADD_NEW_TIMELINE";

export enum TimelineGroupBy {
    WEEK = "week",
    MONTH = "month",
    YEAR = "year",
}

export enum TimelinesActionTypes {
    SET_TIMELINES_LOADING = "@@timelines/SET_TIMELINES_LOADING",
    CREATE_TIMELINE_SET_INPUT = "@@timelines/CREATE_TIMELINE_SET_INPUT",
    CREATE_TIMELINE_REQUEST = "@@timelines/CREATE_TIMELINE_REQUEST",
    CREATE_TIMELINE_SET_ERROR = "@@timelines/CREATE_TIMELINE_SET_ERROR",
    CREATE_TIMELINE_SET_RESULT = "@@timelines/CREATE_TIMELINE_SET_RESULT",
    GET_TIMELINE_REQUEST = "@@timelines/GET_TIMELINE_REQUEST",
    GET_TIMELINE_REQUEST_BY_SHORTCODE = "@@timelines/GET_TIMELINE_REQUEST_BY_SHORTCODE",
    GET_TIMELINE_SET_RESULT = "@@timelines/GET_TIMELINE_SET_RESULT",
    GET_TIMELINES_REQUEST = "@@timelines/GET_TIMELINES_REQUEST",
    GET_TIMELINES_SET_RESULT = "@@timelines/GET_TIMELINES_SET_RESULT",
    GET_TIMELINES_SET_ERROR = "@@timelines/GET_TIMELINES_SET_ERROR",
    UPDATE_TIMELINE_NAME_REQUEST =  "@@timelines/UPDATE_TIMELINE_NAME_REQUEST",
    UPDATE_TIMELINE_CURRENTSPRINT_REQUEST = "@@timelines/UPDATE_TIMELINE_CURRENTSPRINT_REQUEST",
    UPDATE_TIMELINE_CURRENTSPRINT_SET_RESULT = "@@timelines/UPDATE_TIMELINE_CURRENTSPRINT_SET_RESULT",
    ADD_OR_REMOVE_TIMELINE_MEMBER_REQUEST = "@@timelines/ADD_OR_REMOVE_TIMELINE_MEMBER_REQUEST",
    UPDATE_TIMELINE_SORT_ORDER_REQUEST = "@@timelines/UPDATE_TIMELINE_SORT_ORDER_REQUEST",
    SET_TIMELINES_ORDER = "@@timelines/SET_TIMELINES_ORDER",
    UPDATE_TIMELINE_COLOR_REQUEST = "@@timelines/UPDATE_TIMELINE_COLOR_REQUEST",
    UPDATE_TIMELINE_UNITPOINTSRANGE_REQUEST = "@@timelines/UPDATE_TIMELINE_UNITPOINTSRANGE_REQUEST",
    DELETE_TIMELINE_REQUEST = "@@timelines/DELETE_TIMELINE_REQUEST",
    INSERT_OR_UPDATE_TIMELINE = "@@timelines/INSERT_OR_UPDATE_TIMELINE",
    UPDATE_TIMELINE_SET_INPUT = "@@timelines/UPDATE_TIMELINE_SET_INPUT",
    SET_CURRENT_TIMELINE_TASKS_ORDER = "@@timelines/SET_CURRENT_TIMELINE_TASKS_ORDER",
    ADD_OR_REMOVE_TIMELINE_TASKS_REQUEST = "@@timelines/ADD_OR_REMOVE_TIMELINE_TASKS_REQUEST",
    UPDATE_TIMELINE_TASK_REQUEST = "@@timelines/UPDATE_TIMELINE_TASK_REQUEST",
    UPDATE_SORT_BY_TASK_CODE = "@@timelines/UPDATE_SORT_BY_TASK_CODE",
    UPDATE_SORT_BY_PRIORITY = "@@timelines/UPDATE_SORT_BY_PRIORITY",
    UPDATE_SORT_BY_COLUMN = "@@timelines/UPDATE_SORT_BY_COLUMN",
    UPDATE_GROUP_BY = "@@timelines/UPDATE_GROUP_BY",
}

export interface ICreateTimelineInput {
    name: string;
    members: string[];
    color: IStandardColor;
}

export interface ICreateTimelineResult {
    id?: string;
    errors?: string;
}

export interface IUpdateTimelineNameInput {
    id: string;
    name: string;
}

export interface IUpdateTimelineTaskInput {
    id: string;
    timelineStart: number;
    timelineEnd: number;
    isActive: boolean;
}

export interface IAddOrRemoveTimelineMemberInput {
    id: string;
    memberUserID: string;
    isAdd: boolean;
}

export interface IAddOrRemoveTimelineTasksInput {
    id: string;
    taskIDs: string[];
    isAdd: boolean;
}

export interface ITimelineUpdateSortOrderInput {
    id: string;
    beforeTimelineID: string;
    afterTimelineID: string;
}

export interface ITimelineUpdateColorInput {
    id: string;
    color: IStandardColor;
}

export interface ITimelineUpdateUnitPointsRangeInput {
    id: string;
    minUnitPoints: number;
    maxUnitPoints: number;
}

export interface ITimelineUpdateCurrentSprintInput {
    id: string;
    sprintID: string;
    stayedColumnIDs: string[];
}

export interface ITimelineCommonResult {
    id?: string;
    errors?: string;
}

export interface ITimelineTask {
    id: string;
    timelineID: string;
    taskID: string;
    timelineStart: number;
    timelineEnd: number;
    sortOrder: number;
    isActive: boolean;
    task: ITask;
}

export interface ITimeline {
    id: string;
    name: string;
    memberUserIDs: string[];
    sortOrder: number;
    shortcode: string;
    color: IStandardColor;
    timelineTasks: ITimelineTask[];
    columns: IColumn[];
    priorities: IPriority[];
    sprints: ISprint[];
    projects: IProject[];
}

export interface IGetTimelinesResult {
    timelines: ITimeline[];
    errors?: string;
}

export interface ITimelineDeleteInput {
    id: string;
}

export interface ITimelineGetResult {
    timeline?: ITimeline;
    errors?: string;
}

export interface ITimelinesState {
    readonly timelines: any[any];
    readonly timelineOrder: string[];
    readonly timelinesLoading: boolean;
    readonly defaultTimelineColor: IStandardColor;
    readonly createTimelineLoading: boolean;
    readonly createTimelineInput: ICreateTimelineInput;
    readonly createTimelineResult: ICreateTimelineResult;
    readonly getTimelinesLoading: boolean;
    readonly getTimelinesResult: IGetTimelinesResult;
    readonly timelineMap: IStringTMap<ITimeline>;
    readonly timelinesOrder: string[];
    readonly timelineTaskMap: IStringTMap<ITimelineTask>;
    readonly updateTimelineCurrentSprintLoading: boolean;
    readonly updateTimelineInput?: ITimeline;
    readonly sortByTaskCodeAsc?: boolean;
    readonly sortByPriorityAsc?: boolean;
    readonly sortByColumnAsc?: boolean;
    readonly groupBy?: TimelineGroupBy;
}
