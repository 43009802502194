/*!
 * Copyright 2019 CTC. All rights reserved.
 *
 * Licensed under the terms of the LICENSE file distributed with this project.
 */

import {
    Alignment,
    Classes,
    Icon,
    Intent,
    Menu,
    MenuDivider,
    MenuItem,
    Popover,
    Position,
    Switch,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import _ from "lodash";
import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import styled from "styled-components";
import { IApplicationState } from "../../store";
import * as dialogsActions from "../../store/dialogs/actions";
import { IProject } from "../../store/projects/types";
import * as timelinesActions from "../../store/timelines/actions";
import { TimelineGroupBy } from "../../store/timelines/types";

// Props passed from mapStateToProps
interface IPropsFromState {
    isUsingDarkTheme: boolean;
    userLoading: boolean;
    currentProject: IProject;
    sortByTaskCodeAsc?: boolean;
    sortByColumnAsc?: boolean;
    sortByPriorityAsc?: boolean;
    groupBy?: TimelineGroupBy;
}

// Props passed from mapDispatchToProps
interface IPropsFromDispatch {
    openTimelineAddTasksDialog: typeof dialogsActions.openTimelineAddTasksDialog;
    updateSortByTaskCode: typeof timelinesActions.updateSortByTaskCode;
    updateSortByPriority: typeof timelinesActions.updateSortByPriority;
    updateSortByColumn: typeof timelinesActions.updateSortByColumn;
    updateGroupBy: typeof timelinesActions.updateGroupBy;
}

// Component-specific props.
interface IOwnProps {

}

// Combine both state + dispatch props - as well as any props we want to pass - in a union type.
type AllProps = IPropsFromState & IPropsFromDispatch & IOwnProps;

class TimelineUpdateMenu extends React.PureComponent<AllProps> {
    public render() {
        return (
            <Menu className={Classes.ELEVATION_1}>
                <MenuDivider title="Timeline actions" />
                <MenuItem
                    icon={IconNames.ADD_ROW_BOTTOM}
                    text="Add task(s)"
                    disabled={false}
                    shouldDismissPopover={true}
                    onClick={this.handleOpenTimelineAddTasksDialog}
                />
                <MenuItem
                    icon={IconNames.SORT_NUMERICAL}
                    text="Sort ascending by task code"
                    intent={this.props.sortByTaskCodeAsc === true ? Intent.SUCCESS : Intent.NONE}
                    disabled={false}
                    shouldDismissPopover={true}
                    onClick={() => {this.sortByTaskCode(true); }}
                />
                <MenuItem
                    icon={IconNames.SORT_NUMERICAL_DESC}
                    text="Sort descending by task code"
                    intent={this.props.sortByTaskCodeAsc === false ? Intent.SUCCESS : Intent.NONE}
                    disabled={false}
                    shouldDismissPopover={true}
                    onClick={() => {this.sortByTaskCode(false); }}
                />
                <MenuItem
                    icon={IconNames.SORT_NUMERICAL}
                    text="Sort ascending by priority"
                    intent={this.props.sortByPriorityAsc === true ? Intent.SUCCESS : Intent.NONE}
                    disabled={false}
                    shouldDismissPopover={true}
                    onClick={() => {this.sortByPriority(true); }}
                />
                <MenuItem
                    icon={IconNames.SORT_NUMERICAL_DESC}
                    text="Sort descending by priority"
                    intent={this.props.sortByPriorityAsc === false ? Intent.SUCCESS : Intent.NONE}
                    disabled={false}
                    shouldDismissPopover={true}
                    onClick={() => {this.sortByPriority(false); }}
                />
                <MenuItem
                    icon={IconNames.SORT_NUMERICAL}
                    text="Sort ascending by status (column)"
                    intent={this.props.sortByColumnAsc === true ? Intent.SUCCESS : Intent.NONE}
                    disabled={false}
                    shouldDismissPopover={true}
                    onClick={() => {this.sortByColumn(true); }}
                />
                <MenuItem
                    icon={IconNames.SORT_NUMERICAL_DESC}
                    text="Sort descending by status (column)"
                    intent={this.props.sortByColumnAsc === false ? Intent.SUCCESS : Intent.NONE}
                    disabled={false}
                    shouldDismissPopover={true}
                    onClick={() => {this.sortByColumn(false); }}
                />
                <MenuItem
                    icon={IconNames.TIME}
                    text="Group by weeks"
                    intent={this.props.groupBy === TimelineGroupBy.WEEK ? Intent.SUCCESS : Intent.NONE}
                    disabled={false}
                    shouldDismissPopover={true}
                    onClick={() => {this.groupBy(TimelineGroupBy.WEEK); }}
                />
                <MenuItem
                    icon={IconNames.TIME}
                    text="Group by months"
                    intent={
                        this.props.groupBy === TimelineGroupBy.MONTH || _.isUndefined(this.props.groupBy) ?
                        Intent.SUCCESS : Intent.NONE
                    }
                    disabled={false}
                    shouldDismissPopover={true}
                    onClick={() => {this.groupBy(TimelineGroupBy.MONTH); }}
                />
                <MenuItem
                    icon={IconNames.TIME}
                    text="Group by years"
                    intent={this.props.groupBy === TimelineGroupBy.YEAR ? Intent.SUCCESS : Intent.NONE}
                    disabled={false}
                    shouldDismissPopover={true}
                    onClick={() => {this.groupBy(TimelineGroupBy.YEAR); }}
                />
                <MenuItem
                    icon={IconNames.DELETE}
                    text="Clear"
                    disabled={true}
                    shouldDismissPopover={true}
                    onClick={this.handleClear}
                />
            </Menu>
        );
    }

    private handleClear = () => {
        // Do something
    }

    private handleOpenTimelineAddTasksDialog = () => {
        this.props.openTimelineAddTasksDialog(true);
    }

    private sortByTaskCode = (isAsc?: boolean) => {
        this.props.updateSortByTaskCode(this.props.sortByTaskCodeAsc === isAsc ? undefined : isAsc);
    }

    private sortByPriority = (isAsc?: boolean) => {
        this.props.updateSortByPriority(this.props.sortByPriorityAsc === isAsc ? undefined : isAsc);
    }

    private sortByColumn = (isAsc?: boolean) => {
        this.props.updateSortByColumn(this.props.sortByColumnAsc === isAsc ? undefined : isAsc);
    }

    private groupBy = (groupBy: TimelineGroupBy) => {
        this.props.updateGroupBy(groupBy);
    }
}

// It's usually good practice to only include one context at a time in a connected component.
// Although if necessary, you can always include multiple contexts. Just make sure to
// separate them from each other to prevent prop conflicts.
const mapStateToProps = ({ navbar, users, board, timelines}: IApplicationState) => ({
    isUsingDarkTheme: navbar.isUsingDarkTheme,
    userLoading: users.loading,
    currentProject: board.project,
    sortByTaskCodeAsc: timelines.sortByTaskCodeAsc,
    sortByColumnAsc: timelines.sortByColumnAsc,
    sortByPriorityAsc: timelines.sortByPriorityAsc,
    groupBy: timelines.groupBy,
});

// Mapping our action dispatcher to props is especially useful when creating container components.
const mapDispatchToProps = (dispatch: Dispatch) => ({
    openTimelineAddTasksDialog: (isOpen: boolean) =>
        dispatch(dialogsActions.openTimelineAddTasksDialog(isOpen)),
    updateSortByTaskCode: (input?: boolean) =>
        dispatch(timelinesActions.updateSortByTaskCode(input)),
    updateSortByPriority: (input?: boolean) =>
        dispatch(timelinesActions.updateSortByPriority(input)),
    updateSortByColumn: (input?: boolean) =>
        dispatch(timelinesActions.updateSortByColumn(input)),
    updateGroupBy: (input?: TimelineGroupBy) =>
        dispatch(timelinesActions.updateGroupBy(input)),
});

// Now let's connect our component!
// With redux v4's improved typings, we can finally omit generics here.
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TimelineUpdateMenu);
